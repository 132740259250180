import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import SidebarAdmin from '../shared-components/Sidebar-admin';
import { newEmail } from '../shared-components/WordsBank';
import 'react-quill/dist/quill.snow.css';
import '../Styles/Email-management.css';
import Swal from 'sweetalert2';
import { getCurrentUserId, createEmailBody, checkIfBodyEmailExists } from '../services/provider';

const CreateEmail = () => {
    const idUser = getCurrentUserId();
    const initialDataEmail = {
        subject: '',
        body: '',
        userID: idUser,
    };

    const [emailData, setEmailData] = useState(initialDataEmail);
    const [error, setError] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [formKey, setFormKey] = useState(0); // `key` para forzar re-render

    const toolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        ['link', 'image', 'video'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['clean'],
    ];

    const modules = {
        toolbar: toolbarOptions
    };

    useEffect(() => {
        if (isSuccess) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                text: 'Email body saved successfully',
                showConfirmButton: false,
                timer: 3000,
                toast: true,
            }).then(() => setIsSuccess(false));
        }
    }, [isSuccess]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEmailData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleBodyChange = (content) => {
        setEmailData({
            ...emailData,
            body: content
        });
    };

    const handleSave = async () => {
        if (emailData.subject.trim() === '' || emailData.body.trim() === '') {
            setError('Required');
            return;
        }

        const emailExists = await checkIfBodyEmailExists(emailData.subject);
        if (emailExists) {
            setError('Email already exists.');
            return;
        }

        setError('');
        setIsSuccess(true);
        await createEmailBody(emailData);

        resetForm(); // Llamada para forzar el re-render
    };

    // Reiniciar el formulario
    const resetForm = () => {
        setEmailData(initialDataEmail); // Limpiar el estado
        setFormKey(prevKey => prevKey + 1); // Cambiar el valor de `key`
    };

    return (
        <div className='NewEmail' key={formKey}>
            <SidebarAdmin />
            <div style={{ padding: '20px', maxWidth: '850px', margin: '0 auto', border: 'none', borderRadius: '5px' }}>
                <h1 style={{ color: 'white', marginBottom: '20px', marginTop: '0px' }}>{newEmail.newMail}</h1>
                <input
                    name='subject'
                    type="text"
                    placeholder={newEmail.subject}
                    value={emailData.subject}
                    onChange={handleInputChange}
                    style={{ width: '100%', padding: '10px', marginRight: '5px', marginBottom: '15px', borderRadius: '5px' }}
                />
                <div style={{ backgroundColor: 'white', borderRadius: '5px' }} className='divraro'>
                    <ReactQuill
                        modules={modules}
                        style={{ width: 'auto', height: '350px', borderRadius: '15px' }}
                        value={emailData.body}
                        onChange={handleBodyChange}
                    />
                </div>

                {error && <div className='text-danger mb-3'>{error}</div>}

                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', marginTop: '20px' }}>
                    <button className="btn btn-light d-inline-block m-1" onClick={handleSave} style={{ padding: '10px 20px', backgroundColor: 'rgb(53, 215, 156)', color: '#fff', marginRight: '5px', border: 'none' }}>
                        {newEmail.save}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateEmail;
