import React, { useState } from 'react';
import SidebarAdmin from '../shared-components/Sidebar-admin';
import ModalDelete from '../shared-components/Modal-delete.jsx';
import TableComponent from '../shared-components/Table-component';
import ModalViewMore from '../shared-components/Modal-view-more.jsx';
import { getBodyEmails, fetchMailingGroup, getCurrentUserId, updateCampaigns } from '../services/provider.js';
import '../Styles/Background-Table.css';
import Swal from 'sweetalert2';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { editCampaign } from '../shared-components/WordsBank.js';
import ModalCreateCampaign from '../components/Modal-create-campaign.jsx';
import { useDispatch } from 'react-redux';
import { openModal } from '../Store/modalSlice';

const TableCampaign=()=> {
    const userId = getCurrentUserId();
    const dispatch = useDispatch();
    

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return '';
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    };

    const formatDateSchedule = (dateSchedule) => {
        if (!Array.isArray(dateSchedule) || dateSchedule.length === 0) return '';
        return dateSchedule.join('<br>'); 
    };

    const handleViewClick = (item) => {
        // Calls the modal display function from TableComponent
        showModal('view', item);
    };

    const handleDeleteClick = (item) => {
        // Calls the modal removal function from the TableComponent
        showModal('delete', item);
    };

    const handleCreateClick = () => {
        dispatch(openModal());
    };

    const handleEditClick = async (item) => {
        // Emails disponibles para seleccionar como cuerpo del correo
        let availableEmails = await fetchMailingGroup();
        let availableBodyEmails = await getBodyEmails();
        let datesCampaign = [];
        let bodyEmails = [];
        let selectedGroup = ''; // Para almacenar el grupo de correos seleccionado

        const { value: formValues } = await Swal.fire({
            html: `
                <div style="text-align: left; margin-left: 38px; margin-top: 30px;">
                    <h4 style="margin-bottom: 45px; font-weight: bold;">${editCampaign.editCampaign}</h4>
                    <div style="margin-bottom: 25px;">
                        <label for="swal-input1" style="display: block; margin-bottom: 5px;">${editCampaign.nameCampaign}</label>
                        <input id="swal-input1" class="swal2-input" style="width: 92%; margin: 0; background: #FFFFFF;" value="${item.nameCampaign}">
                    </div>

                    <!-- Dropdown para seleccionar el grupo de correos -->
                    <div style="margin-bottom: 25px;">
                        <label for="mailing-group" style="display: block; margin-bottom: 5px;">${editCampaign.group}</label>
                        <select id="mailing-group" class="swal2-input" style="width: 92%; background: #FFFFFF;">
                            <option value="">Select a mailing group</option>
                            ${availableEmails.map(group => `
                                <option value="${group.nameEmailGroup}">${group.nameEmailGroup}</option>
                            `).join('')}
                        </select>
                    </div>

                    <div style="margin-bottom: 25px;">
                        <label for="swal-datepicker" style="display: block; margin-bottom: 5px;">${editCampaign.dates}</label>
                        <input id="swal-datepicker" class="swal2-input" placeholder="Select dates..." style="width: 92%; margin: 0; background: #FFFFFF;" />
                    </div>

                    <!-- Aquí es donde aparecerán dinámicamente las selecciones de cuerpo de correo -->
                    <div id="email-body-section" style="margin-top: 20px;"></div>
                </div>
            `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: 'Save',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#35D79C',
            cancelButtonColor: '#DE3232',
            showCloseButton: true,
            background: '#DFD8E2',
            width: '650px',
            allowOutsideClick: false,
            didRender: () => {
                // Iniciar Flatpickr para seleccionar múltiples fechas
                flatpickr("#swal-datepicker", {
                    mode: "multiple",
                    enableTime: true,
                    dateFormat: "Y-m-d H:i",
                    onChange: (selectedDates) => {
                        const emailBodySection = document.getElementById("email-body-section");
                        emailBodySection.innerHTML = ''; // Limpiar el contenido antes de añadir nuevas fechas
    
                        selectedDates.forEach((date, index) => {
                            const dateFormatted = date.toLocaleString(); // Formatear la fecha para mostrar
                            datesCampaign[index] = dateFormatted; // Almacenar la fecha en el arreglo
    
                            // Agregar selectores para los bodyEmails
                            emailBodySection.innerHTML += `
                                <div style="margin-bottom: 20px;">
                                    <label for="body-email-${index}" style="display: block; margin-bottom: 5px;">Body Email for Date: ${dateFormatted}</label>
                                    <select id="body-email-${index}" class="swal2-input" style="width: 92%; background: #FFFFFF;">
                                        <option value="">Select a body email</option>
                                        ${availableBodyEmails.map(email => `
                                            <option value="${email.bodyEmail}">${email.subject}</option>
                                        `).join('')}
                                    </select>
                                </div>
                            `;
    
                            // Almacenar el cuerpo de correo seleccionado
                            document.getElementById(`body-email-${index}`).addEventListener('change', (e) => {
                                bodyEmails[index] = e.target.value; // Almacenar el cuerpo de correo en el mismo índice que la fecha
                            });
                        });
                    }
                });
    
                // Capturar el grupo de correos seleccionado
                document.getElementById('mailing-group').addEventListener('change', (e) => {
                    selectedGroup = e.target.value; // Almacenar el grupo de correos seleccionado
                });
            },
            preConfirm: async () => {
                const nameCampaign = Swal.getPopup().querySelector('#swal-input1').value;
    
                // Validación
                if (!nameCampaign) {
                    Swal.showValidationMessage('Please enter the campaign name');
                    return false;
                }
    
                if (!selectedGroup) {
                    Swal.showValidationMessage('Please select a mailing group');
                    return false;
                }
    
                if (datesCampaign.length === 0 || bodyEmails.some(email => !email)) {
                    Swal.showValidationMessage('Please select a date and body email for each one');
                    return false;
                }
    
                // Retorna los valores capturados
                return { nameCampaign, selectedGroup, datesCampaign, bodyEmails };
            }
        });
    
        if (formValues) {
            // Extraer los valores de formValues
            const { nameCampaign, selectedGroup, datesCampaign, bodyEmails } = formValues;
    
            const updatedData = {
                nameCampaign,
                group: selectedGroup, // Guardar el grupo seleccionado
                datesCampaign, // Guardar las fechas
                bodyEmails,    // Guardar los cuerpos de correo
                lastUpdate: new Date()
            };
    
            // Aquí guardas los datos como dos arreglos separados, como lo requiere tu cliente
            try {
                await updateCampaigns(item.id, updatedData);
                Swal.fire({ 
                    position: 'top-end',
                    icon: 'success',
                    text: 'Campaign updated successfully!',
                    showConfirmButton: false,
                    timer: 5000,
                    toast: true,
                });
            } catch (error) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    text: 'Error updating campaign',
                    showConfirmButton: false,
                    timer: 5000,
                    toast: true,
                });
            }
        }
    }


    const showModal = (modalType, item) => {
        switch (modalType) {
            case 'view':
                ModalViewMore({
                    title: 'Campaign Details',
                    fields: [
                        { label: 'Campaign name', key: 'nameCampaign' },
                        { label: 'Creation date', key: 'createDate', format: formatTimestamp},
                        { label: 'Last update date', key: 'lastUpdate',format: formatTimestamp},
                        { label: 'Campaigns sent to the following groups', key: 'group'},
                        { label: 'Scheduled date', key: 'datesCampaign', format:formatDateSchedule}
                    ],
                    data: item
                });
                break;
            case 'delete':
                ModalDelete({
                    item,
                    title: 'Campaign',
                    collectionName: 'Campaign',
                    warningMessage: 'You will lose it forever',
                    onSuccessMessage: 'The campaign has been deleted!',
                });
                break;
            default:
                break;
        }
    };
    

    return(
        <div className='Background-Table'>
            <SidebarAdmin/>
            <TableComponent
                tittle={'Campaigns'}
                collectionName="Campaign"
                columnName={['Name']}
                columnsToShow={['nameCampaign']}
                handleViewClick={handleViewClick}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                handleCreateClick={handleCreateClick}
                currentUserId={userId}
                adminFieldName={'userId'}
            />
            <ModalCreateCampaign/>
        </div>
    );

}


export default TableCampaign