import { collection, addDoc, getDocs, query, where, doc, updateDoc, getDoc, deleteDoc, setDoc, limit, startAfter, startAt, onSnapshot, writeBatch } from 'firebase/firestore';
import { auth, db } from '../services/credentials';
import { createUserWithEmailAndPassword,updatePassword, reauthenticateWithCredential, EmailAuthProvider} from 'firebase/auth';



// Function to obtain the current authenticated user ID
export const getCurrentUserId = () => {
    const user = auth.currentUser;
    return user ? user.uid : null;
};


// Function to delete an item from a specified collection
export const deleteItem = async (collectionName, itemId) => {
    try {
        const itemRef = doc(db, collectionName, itemId);
        await deleteDoc(itemRef);
        return true; // Return true on success
    } catch (error) {
        console.error("Error deleting document: ", error);
        return false; // Return false on failure
    }
};


// Function to get the information of the current user
export const getUserInfo = async () => {
    try {
        const userId = getCurrentUserId();

        if (!userId) {
            throw new Error('No user is currently authenticated');
        }

        const userDocRef = doc(db, 'User', userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
            return userDoc.data();
        } else {
            throw new Error('No user data found');
        }
    } catch (error) {
        console.error('Error getting user info:', error);
        return null;
    }
};


// Function to verify the role of the user based on their ID
export const verifyUserRole = async (userId) => {
    try {
        const userDocRef = doc(db, 'User', userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
            return userDocSnap.data().role; // Return the role of the user
        } else {
            return null; // User document doesn't exist
        }
    } catch (error) {
        console.error("Error fetching user role:", error);
        return null;
    }
};

/* ------------------------------------------------------------- COMPANY -----------------------------------------------------*/
// Function to add a company
export const addCompany = async (companyData) => {
    const userId = getCurrentUserId(); // Get the ID of the authenticated user
    const companyCount = await getUserCompanyCount(userId)

    const isFirstCompany = companyCount === 0;

    if (!userId) {
        throw new Error('No authenticated user found');
    }

    const currentDate = new Date();
    const companyRef = await addDoc(collection(db, 'Company'), {
        ...companyData,
        creationDate: currentDate,
        lastUpdate: currentDate,
        state: isFirstCompany ? true : false,
        adminID: userId, // The ID of the user who created the company
        accepted: false,
        deleted: false
    });

    // Updates the user's array of company IDs
    const userRef = doc(db, 'User', userId);
    await updateDoc(userRef, {
        companyIDs: [...(await getUserCompanyIDs(userId)), companyRef.id]
    });
};

// Function to check if an email already exists
export const checkIfEmailCompanyExists = async (email, currentCompanyId = null) => {
    const q = query(collection(db, 'Company'), where('email', '==', email));
    const querySnapshot = await getDocs(q);
    // If there are no results, the email is not in use
    if (querySnapshot.empty) return false;
    // If there are results, we need to check if the email belongs to another company
    for (const doc of querySnapshot.docs) {
        if (doc.id !== currentCompanyId) {
            // If the email is used by a different company, return true
            return true;
        }
    }
    // If no other companies are using the email, return false
    return false;
};


// Function to obtain the array of company IDs of a user. **
const getUserCompanyIDs = async (userId) => {
    const userRef = doc(db, 'User', userId);
    const userDoc = await getDoc(userRef);
    return userDoc.exists() ? userDoc.data().companyIDs || [] : [];
};


// Function to delete a company from the companys list of the user
export const removeCompanyFromUser = async (companyId) => {
    try {
        const userId = getCurrentUserId(); // Get the ID of the authenticated user

        if (!userId) {
            throw new Error('No authenticated user found');
        }

        const userRef = doc(db, 'User', userId);
        const companyIDs = await getUserCompanyIDs(userId);

        // Ensure that only the specified company ID is removed
        const updatedCompanyIDs = companyIDs.filter(id => id !== companyId);

        // Update the user's companyIDs array
        await updateDoc(userRef, { companyIDs: updatedCompanyIDs });

        return true; // Return true on success
    } catch (error) {
        console.error("Error updating user's companyIDs: ", error);
        return false; // Return false on failure
    }
};


// Function to update a company 
export const updateCompany = async (companyId, updatedData) => {
    try {
        const companyRef = doc(db, 'Company', companyId); // Get a reference to the company document
        await updateDoc(companyRef, updatedData); // Update the document with the new data
        return true; // Return true on success
    } catch (error) {
        console.error('Error updating company: ', error);
        return false; // Return false on failure
    }
};

//Delete Company (desactivate company)
export const deleteCompany = async (companyId) => {
    try {
        const companyRef = doc(db, 'Company', companyId);
        await updateDoc(companyRef, { deleted: true });
        return true; // Return true on success
    } catch (error) {
        console.error("Error deleting document: ", error);
        return false; // Return false on failure
    }
};

// Get the number of companies allowed in the user's plan
export const getNumberCompaniesAllowedByPlanForUser = async () => {
    const userId = getCurrentUserId();
    const userRef = doc(db, 'User', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
        throw new Error('User not found');
    }

    const planName = userDoc.data().plan;
    const q = query(collection(db, 'Plan'), where('namePlan', '==', planName));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
        throw new Error('Plan not found');
    }

    const planDoc = querySnapshot.docs[0]; // Take the first document (it should be unique)

    return planDoc.data().numberCompany;
};


// Get the number of companies in the arrangement in the user document
export const getUserCompanyCount = async () => {
    const userId = getCurrentUserId();

    if (!userId) {
        throw new Error('No authenticated user found');
    }

    const userRef = doc(db, 'User', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
        throw new Error('User document not found');
    }

    const companyIDs = userDoc.data().companyIDs || [];
    return companyIDs.length;  // Returns directly the number of companies

};


export const updateCompanyState = async (companyId, newState) => { // **
    try {
        const companyRef = doc(db, 'Company', companyId);
        await updateDoc(companyRef, { state: newState });
    } catch (error) {
        console.error("Error updating company state:", error);
    }
};

// Fetches the total number of documents
export const fetchTotalDocumentsCompany = async (userId) => {
    const queryCollection = query(collection(db, 'Company'), where('adminID', '==', userId), where('accepted', '==', true), where('deleted', '==', false));
    const querySnapshot = await getDocs(queryCollection);
    return querySnapshot.size;
};

// Fetches documents with pagination and optional search
export const fetchCompanyData = async (userId, pageSize, searchTerm = '', lastVisible = null, firstVisiblePages = [], isNextPage = false, isPrevPage = false) => {
    let queryC;
    const queryCollection = query(collection(db, 'Company'), where('adminID', '==', userId), where('accepted', '==', true), where('deleted', '==', false));

    if (searchTerm.trim() !== '') {
        const isEmail = searchTerm.includes('@');
        if (isEmail) {
            queryC = query(queryCollection,
                where('email', '>=', searchTerm),
                where('email', '<=', searchTerm + '\uf8ff'),
                limit(pageSize));
        } else {
            queryC = query(queryCollection,
                where('companyName', '>=', searchTerm),
                where('companyName', '<=', searchTerm + '\uf8ff'),
                limit(pageSize));
        }
    } else if (isNextPage && lastVisible) {
        queryC = query(queryCollection, startAfter(lastVisible), limit(pageSize));
    } else if (isPrevPage && firstVisiblePages.length > 1) {
        queryC = query(queryCollection, startAt(firstVisiblePages[firstVisiblePages.length - 2]), limit(pageSize));
    } else {
        queryC = query(queryCollection, limit(pageSize));
    }

    const querySnapshot = await getDocs(queryC);
    const documents = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().companyName,
        email: doc.data().email,
        legalID: doc.data().legalID,
        state: doc.data().state,
        creationDate: doc.data().creationDate,
        lastUpdate: doc.data().lastUpdate,
    }));

    return {
        documents,
        firstVisible: querySnapshot.docs[0],
        lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1],
        totalDocuments: querySnapshot.size
    };
};

/* --------------------------------------------------------------------------------------------------------------------------------------*/

/* ------------------------------------------------------- TABLE COMPONENT   ------------------------------------------------------------*/

// Function to know collection size. 
export const fetchTotalDocuments = async (collectionName, currentUserId, adminFieldName) => {
    const queryCollection = collection(db, collectionName);
    let q;

    if (collectionName === 'User') {
        // Only counts the admins of the user collection.
        q = query(queryCollection, where('role', '==', 'Administrator'));
    } else {
        // Applies adminID filter for counting other collections
        q = query(queryCollection, where(adminFieldName, '==', currentUserId));
    }

    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
};

// Fetch data (includes pagination and search)
export const fetchData = async ({ collectionName, searchTerm = '', columnsToShow, lastVisible, isNextPage = false, isPrevPage = false, firstVisiblePages = [], pageSize = 5, currentUserId, adminFieldName }) => {
    const queryCollection = collection(db, collectionName);
    let queryC;

    if (collectionName === 'User') {
        // Filter for users with role 'Administrator' in collection User
        const queryUser = query(queryCollection, where('role', '==', 'Administrator'));
        if (searchTerm) {
            const regex = /^[a-zA-Z0-9._%+-]+@$/;
            if (regex.test(searchTerm)) {
                queryC = query(
                    queryUser,
                    where(columnsToShow[1], '>=', searchTerm),
                    where(columnsToShow[1], '<=', searchTerm + '\uf8ff'),
                    limit(pageSize)
                );
            } else {
                queryC = query(
                    queryUser,
                    where(columnsToShow[0], '>=', searchTerm),
                    where(columnsToShow[0], '<=', searchTerm + '\uf8ff'),
                    limit(pageSize)
                );
            }
        } else if (isNextPage && lastVisible) {
            queryC = query(queryUser, startAfter(lastVisible), limit(pageSize));
        } else if (isPrevPage && firstVisiblePages.length > 1) {
            queryC = query(queryUser, startAt(firstVisiblePages[firstVisiblePages.length - 2]), limit(pageSize));
        } else if (firstVisiblePages.length > 0) {
            queryC = query(queryUser, startAt(firstVisiblePages[firstVisiblePages.length - 1]), limit(pageSize));
        } else {
            queryC = query(queryUser, limit(pageSize));
        }
    } else {
        // Filter adminID for other collections
        const queryWithAdmin = query(queryCollection, where(adminFieldName, '==', currentUserId));
        if (searchTerm) {
            const regex = /^[a-zA-Z0-9._%+-]+@$/;
            if (regex.test(searchTerm)) {
                queryC = query(
                    queryWithAdmin,
                    where(columnsToShow[1], '>=', searchTerm),
                    where(columnsToShow[1], '<=', searchTerm + '\uf8ff'),
                    limit(pageSize)
                );
            } else {
                queryC = query(
                    queryWithAdmin,
                    where(columnsToShow[0], '>=', searchTerm),
                    where(columnsToShow[0], '<=', searchTerm + '\uf8ff'),
                    limit(pageSize)
                );
            }
        } else if (isNextPage && lastVisible) {
            queryC = query(queryWithAdmin, startAfter(lastVisible), limit(pageSize));
        } else if (isPrevPage && firstVisiblePages.length > 1) {
            queryC = query(queryWithAdmin, startAt(firstVisiblePages[firstVisiblePages.length - 2]), limit(pageSize));
        } else if (firstVisiblePages.length > 0) {
            queryC = query(queryWithAdmin, startAt(firstVisiblePages[firstVisiblePages.length - 1]), limit(pageSize));
        } else {
            queryC = query(queryWithAdmin, limit(pageSize));
        }
    }
    const querySnapshot = await getDocs(queryC);
    const documents = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
    }));

    const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    const firstVisibleDoc = querySnapshot.docs[0];

    return {
        documents,
        lastVisible: lastVisibleDoc,
        firstVisible: firstVisibleDoc
    };
};

// Function for update
export const subscribeToCollection = (collectionName, loadData, currentUserId, adminFieldName) => {
    const queryCollection = collection(db, collectionName);
    let queryC;

    if (collectionName === 'User') {
        queryC = query(queryCollection, where('role', '==', 'Administrator')); // Query if it is in the User collection
    } else {
        queryC = query(queryCollection, where(adminFieldName, '==', currentUserId)); // Query if it is in the other collections
    }

    const unsubscribe = onSnapshot(queryC, (snapshot) => {
        loadData(); // To reload the current page
    });
    loadData();
    return () => unsubscribe();
};

/* --------------------------------------------------------------------------------------------------------------------------------------*/
/* ------------------------------------------------------------ ADMINISTRATOR -----------------------------------------------------------*/
// Function that verify the email
export const checkEmailExists = async (email, excludeId) => {
    const companiesRef = collection(db, "User");
    const q = query(companiesRef, where("emailAdmin", "==", email));
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.some(doc => doc.id !== excludeId);
};

// Function for update Admin info. 
export const updateAdmin = async (id, updatedData) => {
    const docRef = doc(db, "User", id);
    await updateDoc(docRef, { ...updatedData, lastUpdate: new Date() });
};

export const subscribeToPlans = (setPlans) => {
    const unsubscribe = onSnapshot(collection(db, 'Plan'), (snapshot) => {
        const updatedPlans = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setPlans(updatedPlans);
    });

    return unsubscribe;
};

export const checkIfEmailExists = async (email) => {
    const q = query(collection(db, 'User'), where('email', '==', email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
};

export const createAdministrator = async (administrator) => {
    const { adminName, email, password, planAdmin } = administrator;
    // Create an user in Firebase
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user; // The credencials of the new user
    const currentDate = new Date(); // Capture the current date and time
    const role = 'Administrator'
    const companyIDs = []

    await setDoc(doc(db, 'User', user.uid), {
        role: role,
        companyIDs,
        nameAdmin: adminName,
        email: email,
        creationDate: currentDate,
        lastUpdate: currentDate, // Both dates are current at the time of creation
        plan: planAdmin
    });
};

/* --------------------------------------------------------------------------------------------------------------------------------------*/
/* ------------------------------------------------------------ PLANS -----------------------------------------------------------*/
// Function for obtain plans
export const fetchPlans = async () => {
    const plansRef = collection(db, "Plan");
    const plansSnapshot = await getDocs(plansRef);
    return plansSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};

export const updatePlan=async(idPlan,updatedDataPlan)=>{
    try {
        const planRef = doc(db, 'Plan', idPlan); 
        await updateDoc(planRef, updatedDataPlan); 
        return true; 
    } catch (error) {
        console.error(error);
        return false; 
    }
};
export const checkPlanExists = async (name) => {
    const plansRef = collection(db, "Plan");
    const q = query(plansRef, where("namePlan", "==", name));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
};

export const addPlan=async (dataPlan)=>{
    try {
        await addDoc(collection(db, 'Plan'), {...dataPlan});
        return true;
    } catch (error) {
        throw new Error('An error occurred while saving the plan');
    }
};

/* ------------------------------------------------------------ CLIENT MAIL -----------------------------------------------------------*/

export const checkIfClientEmailExists = async (emailClient) => {
    const q = query(collection(db, 'EmailClient'), where('emailClient', '==', emailClient));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
};

export const createClientMail = async (client) => {
    const userId = getCurrentUserId();
    const currentDate = new Date(); // Obtener la fecha y hora actuales.
    await addDoc(collection(db, 'EmailClient'), {
        ...client, // Agregar los datos del cliente manual.
        creationDate: currentDate, // Fecha de creación.
        lastUpdate: currentDate, // Última fecha de actualización.
        state: false, // Estado inicial del cliente.
        adminID: userId
    });
}

/* --------------------------------------------------------------------------------------------------------------------------------------*/

/* ------------------------------------------------------- MAILING GROUP   ------------------------------------------------------------*/

// Function to add an email group
export const createEmailGroup = async (emailGroupData) => {
    const userId = getCurrentUserId(); 
    if (!userId) {
        throw new Error('No authenticated user found');
    }

    const currentDate = new Date();
    
    await addDoc(collection(db, 'EmailGroup'), {
        ...emailGroupData,
        adminID: userId,
        creationDate: currentDate,
        lastUpdate: currentDate,
    });
};

// Function to get the clients emails of the current admin
export const getClientsEmails = async () => {
    const userId = getCurrentUserId();
    if (!userId) {
        throw new Error('No authenticated user found');
    }

    const q = query(
        collection(db, 'EmailClient'),
        where('adminID', '==', userId)
    );

    try {
        const querySnapshot = await getDocs(q);
        const clientsEmails = querySnapshot.docs.map(doc => doc.data().email)
        return clientsEmails;
    } catch (error) {
        console.error("Error fetching clients' emails: ", error);
        throw new Error('Error fetching clients\' emails');
    }
};

// Funtion to verify name of the group doesn't already exists
export const checkIfMailingGroupExists = async (groupName, currentGroupId) => {
    const q = query(collection(db, 'EmailGroup'), where('nameEmailGroup', '==', groupName));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return false;
    for (const doc of querySnapshot.docs) {
        if (doc.id !== currentGroupId) {
            return true;
        }
    }
    return false;
};

// Function to update a MailingGroup 
export const updateMailingGroup = async (groupID, updatedData) => {
    try {
        const groupRef = doc(db, 'EmailGroup', groupID);
        await updateDoc(groupRef, updatedData); 
        return true; // Return true on success
    } catch (error) {
        console.error('Error updating Email Group: ', error);
        return false; // Return false on failure
    }
};

export const getEmailsFromGroup = async (nameGroup) => {
    const q = query(
        collection(db, 'EmailGroup'),
        where('nameEmailGroup', '==', nameGroup)
    );

    try {
        const querySnapshot = await getDocs(q);
        const listEmail = querySnapshot.docs.map(doc => doc.data().emails)
        return listEmail;
    } catch (error) {
        console.error("Error fetching clients' emails: ", error);
        throw new Error('Error fetching clients\' emails');
    }
}

export const fetchMailingGroup = async () => {
    try {
        const userId = getCurrentUserId();
        if (!userId) {
            throw new Error('No authenticated user found');
        }

        const mailingGroupsRef = collection(db, "EmailGroup");
        const mailingSnapshot = await getDocs(mailingGroupsRef);

        if (mailingSnapshot.empty) {
            console.warn('No mailing groups found for the current user.');
            return [];
        }
        return mailingSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error('Error fetching mailing groups:', error);
    }
}

/**----------------------------------------------------------------------Master Configuration--------------------------------------------- */

export const fetchCompany = async () => {
    const plansRef = collection(db, "Company");
    const plansSnapshot = await getDocs(plansRef);
    return plansSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
};


// Función para actualizar el estado de "accepted" a true
export const handleAcceptCompany = async (companyId, companies, setCompanies) => {
    const companyRef = doc(db, "Company", companyId); // Referencia al documento
    await updateDoc(companyRef, { accepted: true });  // Actualizar el campo "accepted" a true
    setCompanies(companies.filter(company => company.id !== companyId)); // Remover la compañía de la lista local
  };

// Función para eliminar una compañía y su ID de la lista de usuarios
export const handleDeleteCompany = async (companyId, companies, setCompanies) => {
    const companyRef = doc(db, "Company", companyId); // Referencia al documento de la compañía
    
    try {
        // Eliminar el documento de Firestore
        await deleteDoc(companyRef); 
        
        // Actualizar la lista de compañías en el estado
        setCompanies(companies.filter(company => company.id !== companyId));

        // Obtener todos los usuarios
        const usersSnapshot = await getDocs(collection(db, "User")); // Obtener todos los documentos de la colección "User"

        // Iterar sobre cada usuario y eliminar el ID de la compañía de su lista companyIDs
        const batch = writeBatch(db); // Crear un lote para realizar varias escrituras
        
        usersSnapshot.forEach(async (userDoc) => {
            const userId = userDoc.id; // Obtener el ID del usuario
            const userData = userDoc.data(); // Obtener los datos del usuario
            
            // Verificar si el usuario tiene un campo companyIDs
            if (userData.companyIDs && userData.companyIDs.includes(companyId)) {
                // Filtrar el ID de la compañía que se va a eliminar
                const updatedCompanyIDs = userData.companyIDs.filter(id => id !== companyId);
                
                // Crear una referencia al documento del usuario
                const userRef = doc(db, "User", userId);
                
                // Agregar la actualización al lote
                batch.update(userRef, { companyIDs: updatedCompanyIDs });
            }
        });

        // Ejecutar todas las actualizaciones en el batch
        await batch.commit(); 

        console.log("Compañía eliminada y lista de IDs actualizada en los usuarios");
    } catch (error) {
        console.error("Error al eliminar la compañía y actualizar los IDs de los usuarios: ", error);
    }
};


/* --------------------------------------------------------------------------------------------------------------------------------------*/

/* ------------------------------------------------------- EMAIL BODY ------------------------------------------------------------*/

// Function to create Email Body
export const createEmailBody = async (emailBodyData) => {
    const currentDate = new Date();
    await addDoc(collection(db,'BodyEmail'), {
        ...emailBodyData, 
        creationDate: currentDate,
        lastUpdate: currentDate,
    });
};

// Function that fetch all Body Information
export const getBodyEmails = async () => {
    const userId = getCurrentUserId();
    if (!userId) {
        throw new Error('No authenticated user found');
    }

    const q = query(
        collection(db, 'BodyEmail'),
        where('userID', '==', userId)
    );

    try {
        const querySnapshot = await getDocs(q);
        const mailingGroups = querySnapshot.docs.map(doc => ({
            id: doc.id, // Agrega el id del documento
            subject: doc.data().subject // Incluye el nombre del grupo
        }));
        return mailingGroups;
    } catch (error) {
        console.error('Error fetching Body Emails:', error);
        return []; // Retorna un array vacío en caso de error
    }
}

// Edit Body Email
export const fetchEmailData = async (setSubject, setBody) => {
    const docRef = doc(db, "emails", "emailDocId");  // 'emailDocId' es el ID del documento en Firestore.
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      setSubject(docSnap.data().subject);
      setBody(docSnap.data().body);
    } else {
      console.log("No such document!");
    }
};

export const updateBodyEmail = async (item, editSubject, editBody) => {
    const currentDate = new Date(); // Capture the current date and time
    if (!item?.id) return;  // Verifica que el item tenga un id válido
    const docRef = doc(db, 'BodyEmail', item.id);  // Reemplaza 'Email' con el nombre de tu colección
    await updateDoc(docRef, {
        subject: editSubject,
        body: editBody, 
        lastUpdate: currentDate,
    });
};

/* --------------------------------------------------------------------------------------------------------------------------------------*/

/* -------------------------------------------------------     SEND EMAILS    ------------------------------------------------------------*/
export const getClientsData = async () => {
    const userId = getCurrentUserId();
    if (!userId) {
        throw new Error('No authenticated user found');
    }

    const q = query(
        collection(db, 'EmailClient'),
        where('adminID', '==', userId)
    );

    try {
        const querySnapshot = await getDocs(q);
        const mailingGroups = querySnapshot.docs.map(doc => ({
            id: doc.id, // Agrega el id del documento
            email: doc.data().email // Incluye el nombre del grupo
        }));
        return mailingGroups;
    } catch (error) {
        console.error('Error fetching Email Groups:', error);
        return []; // Retorna un array vacío en caso de error
    }
};


export const getDataGroups = async () => {
    const userId = getCurrentUserId();
    if (!userId) {
        throw new Error('No authenticated user found');
    }

    const q = query(
        collection(db, 'EmailGroup'),
        where('adminID', '==', userId)
    );

    try {
        const querySnapshot = await getDocs(q);
        const mailingGroups = querySnapshot.docs.map(doc => ({
            id: doc.id, // Agrega el id del documento
            nameEmailGroup: doc.data().nameEmailGroup // Incluye el nombre del grupo
        }));
        return mailingGroups;
    } catch (error) {
        console.error('Error fetching Email Groups:', error);
        return []; // Retorna un array vacío en caso de error
    }
};


export const getDataCompany = async () => {
    const userId = getCurrentUserId();
    if (!userId) {
        throw new Error('No authenticated user found');
    }
    const collectionRef = collection(db, 'Company');
    const companySnapshot = await getDocs(collectionRef);
    for (const doc of companySnapshot.docs) {
        const idAdmin = doc.data().adminID;
        const state = doc.data().state;
        if (userId === idAdmin && state) {
            // Asegúrate de que estás retornando el formato correcto
            return {
                id: doc.id, // Cambia 'companyID' a 'id' aquí
                email: doc.data().email,
                name: doc.data().companyName // Esto coincide con lo que verificas en saveEmailData
            };
        }
    }
    console.log("This user doesn't have active companies");
    return null;
};


export const saveEmailData= async (emailData) => {
    const { selectedEmails, selectedBodyEmail, sendDate, isSend } = emailData;
    const user = getCurrentUserId();
    const currentDate = new Date()

    // Verificar empresa activa
    const company = await getDataCompany();
    if (!company) {
        console.log("Error: No hay empresa activa");
        return { error: "This user doesn't have active companies." };
    }

    // Validar los datos antes de guardar
    if (!user) {
        console.error('Error: user no está definido');
        return;
    }

    // Validar que selectedEmails no sea un array vacío
    if (!Array.isArray(selectedEmails) || selectedEmails.length === 0) {
        console.error('Error: selectedEmails no es un array o está vacío');
        return;
    }

    let dateP;
    try {
        if (sendDate instanceof Date && !isNaN(sendDate.getTime())) {
            // Convertimos siempre `sendDate` a string en el formato 'YYYY/MM/DD HH:MM'
            const year = sendDate.getFullYear();
            const month = String(sendDate.getMonth() + 1).padStart(2, "0");
            const day = String(sendDate.getDate()).padStart(2, "0");
            const hour = String(sendDate.getHours()).padStart(2, "0");
            const minute = String(sendDate.getMinutes()).padStart(2, "0");
            dateP = `${year}/${month}/${day} ${hour}:${minute}`;
        } else {
            throw new Error('Invalid sendDate format');
        }
    } catch (error) {
        console.error('Error al procesar sendDate:', error.message);
        return { error: 'Invalid sendDate' };
    }

    // Intentar guardar el documento solo si las validaciones pasaron
    try {
        const docRef = await addDoc(collection(db, 'Email'), {
            adminId: user,
            companyId: company.id,
            companyEmail: company.email,
            companyName: company.name,
            Emails: selectedEmails,
            BodyEmail: selectedBodyEmail,
            Date: dateP, // Siempre en formato string
            isSend: isSend,
            creationDate: currentDate,
            lastUpdate: currentDate,
        });
        console.log('Document ID: ', docRef.id);
        console.log('Revisar base de datos');
    } catch (error) {
        console.error('Error al agregar documento: ', error);
        throw new Error(error);
    }
};


export const checkIfBodyEmailExists = async (subject) => {
    const q = query(collection(db, 'BodyEmail'), where('subject', '==', subject));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
};

/* --------------------------------------------------------------------------------------------------------------------------------------*/

/* -------------------------------------------------------   EMAILS    ------------------------------------------------------------*/
export const loadEmails = async (setEmails) => {
    const company = await getDataCompany();

    if (!company) {
        console.log("No hay empresa activa para cargar correos.");
        setEmails([]); 
        return;
    }

    // Crear la consulta para obtener los correos de la empresa activa
    const emailsQuery = query(
        collection(db, 'Email'),
        where('companyId', '==', company.id)
    );

    const querySnapshot = await getDocs(emailsQuery);
    const emailsData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        
        // Convertir los campos de fecha a un formato legible
        return {
            id: doc.id,
            ...data,
            Date: data.Date,
        };
    });
    setEmails(emailsData);
};


export const deleteEmail = async (emailId) => {
    try {
        const emailRef = doc(db, 'Email', emailId);
        await deleteDoc(emailRef);
        console.log(`Email with ID ${emailId} has been deleted successfully.`);
        return true; 
    } catch (error) {
        console.error("Error deleting email:", error);
    }
};

export const updateEmailData = async (emailData) => {
    const { id, selectedEmails, selectedBodyEmail, sendDate, isSend } = emailData;
    const user = getCurrentUserId();
    const currentDate = new Date();

    // Verificar empresa activa
    const company = await getDataCompany();
    if (!company) {
        console.log("Error: No hay empresa activa"); // Confirmación en consola
        return { error: "This user doesn't have active companies." };
    }

    // Validar el ID de usuario
    if (!user) {
        console.error('Error: user no está definido');
        return { error: "No authenticated user found" };
    }

    // Validar que selectedEmails no esté vacío
    if (!Array.isArray(selectedEmails) || selectedEmails.length === 0) {
        return { error: 'You must select at least one addressee' };
    }

    let dateP;
    try {
        if (sendDate instanceof Date && !isNaN(sendDate.getTime())) {
            // Convertimos siempre `sendDate` a string en el formato 'YYYY/MM/DD HH:MM'
            const year = sendDate.getFullYear();
            const month = String(sendDate.getMonth() + 1).padStart(2, "0");
            const day = String(sendDate.getDate()).padStart(2, "0");
            const hour = String(sendDate.getHours()).padStart(2, "0");
            const minute = String(sendDate.getMinutes()).padStart(2, "0");
            dateP = `${year}/${month}/${day} ${hour}:${minute}`;
        } else {
            throw new Error('Invalid sendDate format');
        }
    } catch (error) {
        console.error('Error al procesar sendDate:', error.message);
        return { error: 'Invalid sendDate' };
    }

    // Intentar actualizar el documento solo si las validaciones pasaron
    try {
        const docRef = doc(db, 'Email', id); // Referencia al documento existente
        await updateDoc(docRef, {
            adminId: user,
            companyId: company.id,
            companyEmail: company.email,
            companyName: company.name,
            Emails: selectedEmails,
            BodyEmail: selectedBodyEmail,
            Date: dateP,
            isSend: isSend,
            lastUpdate: currentDate, // Fecha de última actualización
        });
        console.log('Documento actualizado con éxito:', id);
        return { success: true };
    } catch (error) {
        console.error('Error al actualizar documento:', error);
        return { error: 'Error updating this email' };
    }
};
/* ------------------------------------------------------- CAMPAIGNS ------------------------------------------------------------*/

export const checkIfCampaignExists = async (nameCampaign) => {
    const q = query(collection(db, 'Campaign'), where('nameCampaign', '==', nameCampaign));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
};

export const createCampaign = async (campaignData) => {
    const currentDate = new Date();
    const userID = getCurrentUserId();
    const { nameCampaign, group, datesCampaign, bodyEmails } = campaignData;

    const company = await getDataCompany(); 
    if (!company || !company.id || !company.email) {
        console.error('Error: company no está definido correctamente');
        return; // Detener la ejecución si company no es válido
    }
  
    if (!userID) {
      throw new Error("No authenticated user found");
    }
  
    // Convertimos las fechas al formato string 'YYYY/MM/DD HH:MM'
    const formattedDatesCampaign = datesCampaign.map(date => {
      const year = date.year;
      const month = String(date.monthIndex + 1)// Mes en dos dígitos
      const day = String(date.day).padStart(2, "0");  // Día en dos dígitos
      const hour = String(date.hour)// Hora en dos dígitos
      const minute = String(date.minute).padStart(2, "0"); // Minuto en dos dígitos
      return `${year}/${month}/${day} ${hour}:${minute}`; // Formato deseado
    });
  
    await addDoc(collection(db, "Campaign"), {
      nameCampaign,
      group,
      datesCampaign: formattedDatesCampaign, // Fechas como strings en el formato 'YYYY/MM/DD HH:MM'
      bodyEmails,
      send: false,
      createDate: currentDate, // Fecha actual en formato ISO
      lastUpdate: currentDate,
      userId: userID,
      companyId: company.id,
      companyName: company.name,
      companyEmail: company.email
    });
  };

export const subscribetoEmailGroup = (setEmailGroup) => {
    const userId = getCurrentUserId();
    // Crear una consulta para filtrar por adminID igual al userId de getCurrentUserId
    const emailGroupQuery = query(
        collection(db, 'EmailGroup'),
        where('adminID', '==', userId)
    );

    // Escuchar los cambios en la colección de grupos filtrada por adminID
    const unsubscribeGroups = onSnapshot(emailGroupQuery, (snapshot) => {
        const updatedGroups = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setEmailGroup(updatedGroups);
    });

    return unsubscribeGroups;
}


export const subscribetoBodyEmails = (setEmails) => {
    const userId = getCurrentUserId();
    // Crear una consulta para filtrar por userID igual al userId de getCurrentUserId
    const emailQuery = query(
        collection(db, 'BodyEmail'),
        where('userID', '==', userId)
    );

    // Escuchar los cambios en la colección de correos filtrada por userID
    const unsubscribeEmails = onSnapshot(emailQuery, (snapshot) => {
        const updatedEmails = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setEmails(updatedEmails);
    });

    return unsubscribeEmails;
}


export const updateCampaigns = async (campaignId, campaignData) => {
    const currentDate = new Date();
    const userID = getCurrentUserId();
    const { nameCampaign, group, datesCampaign, bodyEmails } = campaignData;

    const company = await getDataCompany(); 
    if (!company || !company.id || !company.email) {
        console.error('Error: company no está definido correctamente');
        return; // Detener la ejecución si company no es válido
    }
  
    if (!userID) {
      throw new Error("No authenticated user found");
    }
  
    // Convertimos las fechas al formato string 'YYYY/MM/DD HH:MM'
    const formattedDatesCampaign = datesCampaign.map(date => {
      const year = date.year;
      const month = String(date.monthIndex + 1); // Mes en dos dígitos
      const day = String(date.day).padStart(2, "0");  // Día en dos dígitos
      const hour = String(date.hour); // Hora en dos dígitos
      const minute = String(date.minute).padStart(2, "0"); // Minuto en dos dígitos
      return `${year}/${month}/${day} ${hour}:${minute}`; // Formato deseado
    });
  
    const campaignRef = doc(db, "Campaign", campaignId);

    await updateDoc(campaignRef, {
      nameCampaign,
      group,
      datesCampaign: formattedDatesCampaign, // Fechas como strings en el formato 'YYYY/MM/DD HH:MM'
      bodyEmails,
      lastUpdate: currentDate, // Fecha de actualización actual
      userId: userID,
      companyId: company.id,
      companyName: company.name,
      companyEmail: company.email
    });
};

/* ------------------------------------------------------- MASTER CONFIGURATION------------------------------------------------------------*/
//Function to obtain the values ​​of hours and minutes
export const fetchFrequencySending = async () => {
    try {
      const docRef = doc(db, 'FrequencySending', 'ia2P4lXfHsZzhSxU7Tae');
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const { hours, minutes } = docSnap.data();
        return { hours, minutes };
      } else {
        console.log("No such document!");
        return { hours: 0, minutes: 0 };
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      throw error;
    }
};
  
  // Function to update the values ​​of hours and minutes
export const updateFrequencySending = async (hours, minutes) => {
    try {
        const docRef = doc(db, 'FrequencySending', 'ia2P4lXfHsZzhSxU7Tae'); 
        await updateDoc(docRef, { hours: Number(hours), minutes: Number(minutes) });
    } catch (error) {
        console.error("Error updating document:", error);
        throw error;
    }
};

/* ------------------------------------------------------- CALENDAR HOME PAGE ADMIN------------------------------------------------------------*/
//Function to get campaign documents from firebase
export const getDataCampaigns = async () => {
    const userId = getCurrentUserId();
    if (!userId) {
        throw new Error('No authenticated user found');
    }

    try {
        // Check active company
        const activeCompany = await getDataCompany();
        if (!activeCompany) {
        console.log("No active company found for this user");
        return [];  // Returns an empty array if there is no active company
        }

        // Check campaigns associated with the user and the active company
        const q = query(
        collection(db, 'Campaign'),
        where('userId', '==', userId),
        where('companyId', '==', activeCompany.id)  // Verify that the campaign is associated with the active company
        );

        const querySnapshot = await getDocs(q);
        const campaigns = querySnapshot.docs.map(doc => doc.data());
        return campaigns;

    } catch (error) {
        console.error("Error fetching campaigns for admin: ", error);
        throw new Error('Error fetching campaigns');
    }
};


//Function to get documents from emails from firebase
export const getEmailScheduled = async () => {
    try {
        //Check active company
        const activeCompany = await getDataCompany();
        if (!activeCompany) {
            console.log("No active company found for this user");
            return [];
        }

        // Get email collection
        const emailsRef = collection(db, 'Email');
        const emailSnapshot = await getDocs(emailsRef);

        // Filter scheduled emails
        const scheduledEmails = emailSnapshot.docs
            .map(doc => doc.data())
            .filter(email => email.companyId === activeCompany.id && email.isSend === false);

        return scheduledEmails;
    } catch (error) {
        console.error("Error fetching scheduled emails:", error);
        throw new Error('Error fetching emails');
    }
};

/* -------------------------------------------------------CHANGE PASSWORD------------------------------------------------------------*/
// Function to reauthenticate the user with their old password
export const reauthenticateUser = async (oldPassword) => {
    const user = auth.currentUser;
    if (!user) throw new Error("No authenticated user.");

    const credential = EmailAuthProvider.credential(user.email, oldPassword);
    await reauthenticateWithCredential(user, credential);
};

// Function to update the user's password 
export const changeUserPassword = async (newPassword) => {
    const user = auth.currentUser;
    if (!user) throw new Error("No authenticated user.");

    await updatePassword(user, newPassword);
};
