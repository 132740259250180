import Swal from 'sweetalert2';
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import React, { useState, useEffect } from 'react';
import { checkIfCampaignExists, createCampaign, subscribetoBodyEmails, subscribetoEmailGroup, fetchFrequencySending } from '../services/provider';
import { modalCreateCampaign } from '../shared-components/WordsBank';
import {  useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../Store/modalSlice';


const ModalCreateCampaign = () =>{

    const defaultEntry = {
        nameCampaign: '',
        group: '',
        datesCampaign: [],
        bodyEmails: []
    };

    const [campaign, setCampaign] = useState(defaultEntry);
    const [errors, setErrors] = useState({ nameCampaign: '', group: '', datesCampaign: '' });
    const [isSuccess, setIsSuccess] = useState(false); 
    const [groups, setGroups] = useState([]);
    const [emails, setEmails] = useState([]);
    const [bodyEmailFields, setBodyEmailFields] = useState([]);
    const [timeIntervals, setTimeIntervals] = useState({ hours: 1, minutes: 1 });

    const dispatch = useDispatch();
    const show = useSelector((state) => state.modal.isOpen);

    useEffect(() => {
        //Get intervals for the TimePicker
        const getFrequencySending = async () => {
            try {
                const intervals = await fetchFrequencySending();
                setTimeIntervals(intervals);
            } catch (error) {
                console.error("Error fetching frequency sending intervals:", error);
            }
        };
        getFrequencySending();
        
        // Escuchar en tiempo real los cambios en la colección de planes
        const EmailGroupsQuery = subscribetoEmailGroup(setGroups);
        return () => {
            EmailGroupsQuery();
        };
    }, []);

    useEffect(() => { 
        const BodyEmailQuery = subscribetoBodyEmails(setEmails)
        return () =>{
            BodyEmailQuery();
        };

    }, []);

    // Actualizar los selects para los bodyEmails basado en la cantidad de fechas
    useEffect(() => {
        const numberOfDates = campaign.datesCampaign.length;
        if (numberOfDates > 0 && numberOfDates <= 5) {
            const emailInputs = Array(numberOfDates).fill('');
            setBodyEmailFields(emailInputs);
        } else {
            setBodyEmailFields([]);
        }
    }, [campaign.datesCampaign]);

    const captureInputs = (e) => {
        const { name, value } = e.target;
        setCampaign({ ...campaign, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleBodyEmailChange = (e, index) => {
        const updatedBodyEmails = [...campaign.bodyEmails];
        updatedBodyEmails[index] = e.target.value;
        setCampaign({ ...campaign, bodyEmails: updatedBodyEmails });
    };

    const ValidateInputs = () => {
        const newErrors = { nameCampaign: '', group: '', datesCampaign: '' };
        let isValid = true;

        if (!campaign.nameCampaign) {
            newErrors.nameCampaign = 'Campaign name is required';
            isValid = false;
        }
        if (campaign.nameCampaign.length < 4) {
            newErrors.nameCampaign = 'Campaign name must be at least 4 characters';
            isValid = false;
        }
        if (campaign.nameCampaign.length > 100) {
            newErrors.nameCampaign = 'Campaign name must be no longer than 100 characters';
            isValid = false;
        }
        if (!campaign.group) {
            newErrors.group = 'You need to define a group for the campaign';
            isValid = false;
        }
        if (campaign.datesCampaign.length === 0) {
            newErrors.datesCampaign = 'You need to select dates for the campaign';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    useEffect(() => {
        if (isSuccess) {
            Swal.fire({
                position: 'top-end', // Position in the top right corner
                icon: 'success',
                text: 'Campaign has been sucsessfully created!',
                showConfirmButton: false, // Remove the confirm button
                timer: 5000, // Message will disappear after 5 seconds
                toast: true, // Convert the alert into a toast notification
            }).then(() => setIsSuccess(false)); // Resetting the status after displaying the alert
        }
    }, [isSuccess]);


    const saveCampaign = async (e) => {
        e.preventDefault();

        if (!ValidateInputs()) {
            return;
        }

        const campaignExists = await checkIfCampaignExists(campaign.nameCampaign);
        if (campaignExists) {
            setErrors({ ...errors, nameCampaign: 'A Campaign with this name already exists.' });
            return;
        }

        try {
            
            console.log(typeof campaign.datesCampaign)
            console.log(campaign)
            console.log(campaign.datesCampaign)
            createCampaign(campaign);
            setErrors({ nameCampaign: '', group: '', datesCampaign: '', bodyEmails: '' });
            setCampaign(defaultEntry);
            setIsSuccess(true);
            dispatch(closeModal());
        } catch (error) {
            console.log(error);
            setErrors({ ...errors, global: 'An error occurred while saving the campaign' });
        }
    };

    
    // Reset inputs when modal closes
    const handleClose = () => {
        setCampaign(defaultEntry); // Reset the form fields
        setErrors({nameCampaign: '', group: '', datesCampaign: '', bodyEmails: ''}); // Clear errors
        dispatch(closeModal());
    };

    if (!show) {
        return null;
    }
    
    return(
        <div className="modal d-block" style={{ background: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '50px' }}>
            <div className="modal-dialog" style={{ margin: 'auto', maxWidth: '100%', width: '640px' }}>
            <div className="modal-content" style={{ background: 'black', padding: '28px', borderRadius: '10px', marginBottom: '50px' }}>
                <div className="modal-header" style={{ marginBottom: '10px' }}>
                    <span className="modal-title text-white fs-3">{modalCreateCampaign.newCampaign}</span>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} style={{ filter: 'invert(1)', marginBottom: '30px' }}></button>
                </div>
                <div className="modal-body">
                    <form onSubmit={saveCampaign} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div className="mb-3" style={{ width: '100%', maxWidth: '800px' }}>
                            <label htmlFor="nameCampaign" className="form-label fs-6 text-white">{modalCreateCampaign.campaignName}</label>
                            <input onChange={captureInputs} value={campaign.nameCampaign} type="text" name='nameCampaign' className="form-control" />
                            {errors.nameCampaign && <div className='text-danger'>{errors.nameCampaign}</div>}
                        </div>

                        <div className="mb-3" style={{ width: '100%', maxWidth: '800px' }}>
                            <label htmlFor="GroupEmails" className="form-label fs-6 text-white">{modalCreateCampaign.selectGroup}</label>
                            <select onChange={captureInputs} value={campaign.group} name='group' className="form-select">
                                <option value="">{modalCreateCampaign.selectGroup}</option>
                                {groups.map(group => (
                                    <option key={group.id} value={group.nameGroup}>{group.nameEmailGroup}</option>
                                ))}
                            </select>
                            {errors.group && <div className='text-danger'>{errors.group}</div>}
                        </div>

                        <div className="mb-3" style={{ width: '100%', maxWidth: '800px' }}>
                            <label htmlFor="datesCampaign" className="form-label fs-6 text-white">{modalCreateCampaign.selectDatesAndTimes}</label>
                            <DatePicker
                                multiple
                                value={campaign.datesCampaign}
                                onChange={dates => setCampaign({ ...campaign, datesCampaign: dates })}
                                inputClass='form-control'
                                plugins={[<TimePicker position="bottom" hideSeconds hStep={timeIntervals.hours} mStep={timeIntervals.minutes}/>]}
                            />
                            {errors.datesCampaign && <div className='text-danger'>{errors.datesCampaign}</div>}
                        </div>

                        {bodyEmailFields.map((_, index) => (
                            <div key={index} className="mb-3" style={{ width: '100%', maxWidth: '800px' }}>
                                <label htmlFor={`bodyEmail${index}`} className="form-label fs-6 text-white">{modalCreateCampaign.bodyEmailForDate} {index + 1}</label>
                                <select onChange={(e) => handleBodyEmailChange(e, index)} value={campaign.bodyEmails[index] || ''} name={`bodyEmail${index}`} className="form-select">
                                    <option value="">{modalCreateCampaign.selectBodyEmail}</option>
                                    {emails.map(email => (
                                        <option key={email.id} value={email.bodyEmail}>{email.subject}</option>
                                    ))}
                                </select>
                            </div>
                        ))}

                        <div className="mb-3" style={{ width: '100%', maxWidth: '800px' }}>
                            <button type='submit' className='btn btn-primary' style={{ width: '90px', height: '40px' }}>{modalCreateCampaign.create}</button>
                        </div>
                    </form>
                </div>

                <div className="modal-footer" style={{ marginTop: '40px', marginBottom: '-28px' }}>
                    <button type="button" className="btn btn-danger" style={{ width: '80px', height: '40px', marginTop: '25px' }} onClick={handleClose}>{modalCreateCampaign.cancel}</button>
                </div>
            </div>
        </div>
    </div>

    );





}

export default ModalCreateCampaign
