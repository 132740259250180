import 'react-big-calendar/lib/css/react-big-calendar.css';
import {customCalendar} from '../shared-components/WordsBank.js';

const CustomToolbar = (toolbar) => {
  return (
    <div className="rbc-toolbar d-flex flex-wrap justify-content-between align-items-center" style={{backgroundColor:' #7839CD',color:'#FFFFFF',padding: '3vh'}}>
      {/* Botones predeterminados (Today) */}
      <div className="rbc-btn-group mb-2 mb-md-0">
        <button type="button" className="btn btn-primary me-2" onClick={() => toolbar.onNavigate('TODAY')}>
          {customCalendar.today}
        </button>
      </div>

      <div className="rbc-btn-group d-flex align-items-center mb-2 mb-md-0">
        {/* Botón de "Back" */}
        <button type="button" className="btn btn-outline-secondary me-2" onClick={() => toolbar.onNavigate('PREV')}>
          <i className="bi bi-chevron-left"></i>
        </button>

        {/* Mes en el centro */}
        <span className="rbc-toolbar-label mx-2">{toolbar.label}</span>

        {/* Botón de "Next" */}
        <button type="button" className="btn btn-outline-secondary" onClick={() => toolbar.onNavigate('NEXT')}>
          <i className="bi bi-chevron-right"></i>
        </button>
      </div>
      {/* Botones predeterminados (Month, Week, Day) */}
      <div className="rbc-btn-group mb-2 mb-md-0">
        <button type="button" className="btn btn-outline-primary me-2" onClick={() => toolbar.onView('month')}>
          {customCalendar.month}
        </button>
        <button type="button" className="btn btn-outline-primary me-2" onClick={() => toolbar.onView('week')}>
          {customCalendar.week}
        </button>
        <button type="button" className="btn btn-outline-primary" onClick={() => toolbar.onView('day')}>
          {customCalendar.day}
        </button>
      </div>
    </div>
  );
};

export default CustomToolbar;
