import React, { useState, useEffect} from 'react';
import { principal } from '../shared-components/WordsBank.js';
import {fetchPlans} from '../services/provider.js';
import Swal from 'sweetalert2';

const ViewPlan=()=>{
    const [dataCollection, setDataCollection] = useState([]);
    useEffect(() => {
        const getData = async () => {
            try {
                const plans = await fetchPlans(); 
                setDataCollection(plans);
            } catch (error) {
                console.log(error);
            }
        };
        getData(); 
    }, []);

    //Sort by price from lowest to highest cost
    const sortedPlans = dataCollection.sort((a, b) => a.price - b.price);

    const groupCards = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };
  
    const groupedPlans = groupCards(sortedPlans, 3);
    const currencySymbol = '$';
  
    const colors = [
        { backgroundColor: '#D02F7C', color: '#fff' },
        { backgroundColor: '#35D79C', color: '#fff' },
        { backgroundColor: '#7839CD', color: '#fff' }
    ];

    const handleContactClick = (planName, colorPlan) => {
        Swal.fire({
            html: `
            <form id="contactForm" style="text-align: left;">
                <input type="hidden" name="access_key" value="21b08661-1b36-486a-b6e4-8721c25ab21d">
                <input type="hidden" name="subject" value="Contact Us Vivamail"> <!-- Asunto personalizado -->
                <p style="font-size: 1.5rem; color: ${colorPlan}; margin-top: 20px; margin-bottom: 20px; text-align: center;">
                    Contact Us about ${planName}
                </p>
                <div class="mb-3" style="width: 100%; max-width: 800px; margin-bottom: 15px;">
                    <label for="name" class="form-label">Your Name</label>
                    <input type="text" class="form-control" id="name" name="name" required>
                </div>
                <div class="mb-3" style="width: 100%; max-width: 800px; margin-bottom: 15px;">
                    <label for="email" class="form-label">Your Email address</label>
                    <input type="email" class="form-control" id="email" name="email" required>
                </div>
                <div class="mb-3">
                    <label for="message" class="form-label">Your Message or Question</label>
                    <textarea class="form-control" id="message" name="message" rows="3" required></textarea>
                </div>
                <input type="hidden" name="plan" value="${planName}">
                <div style="text-align: center;">
                    <button type="submit" class="btn btn-custom" style="background-color: ${colorPlan}; color: white; width: 160px; height: 40px;">
                        Send
                    </button>
                </div>
            </form>`,
            showConfirmButton: false,
            background: '#000',
            showCloseButton: true,
            allowOutsideClick: false,
        });
    
        // Manejar el envío del formulario
        document.getElementById('contactForm').addEventListener('submit', async (event) => {
            event.preventDefault(); // Prevenir el envío automático del formulario
    
            // Recopilar datos del formulario
            const formData = new FormData(event.target);
    
            try {
                const response = await fetch('https://api.web3forms.com/submit', {
                    method: 'POST',
                    body: formData,
                });
    
                if (response.ok) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Message sent successfully!',
                        showConfirmButton: false,
                        timer: 2000,
                        toast: true
                    });
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Failed to send the message. Please try again.',
                        showConfirmButton: false,
                        timer: 2000,
                        toast: true
                    });
                }
            } catch (error) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'An error occurred. Please try again later.',
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true
                });
            }
        });
    };
    
    

    
    return(
        <div className="container-md" style={{ width: '100%'}}>
                    <div id="planCarousel" className="carousel slide" data-bs-ride="carousel" style={{marginTop:'5%'}}>
                        <div className="carousel-inner">
                        {groupedPlans.map((group, idx) => (
                            <div className={`carousel-item ${idx === 0 ? 'active' : ''}`} key={idx}>
                                <div className="row justify-content-center">
                                {group.map((list, index) => {
                                    const colorIndex = index % colors.length;
                                    const cardStyle = colors[colorIndex];
                                    return (
                                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3" key={list.id}>
                                        <div className="card mb-4 mx-2 card-hover-effect" style={{ backgroundColor: '#151718', borderColor: cardStyle.backgroundColor, borderRadius: '10px', color: '#F5F5F5', minHeight: '400px', maxHeight: '600px' }}>
                                            <div className="card-body d-flex flex-column" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                                <div className="flex-grow-1" style={{ marginLeft: '3px', overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                                                    <h5 className="card-title" style={{ color: cardStyle.backgroundColor, marginTop: '25px' }}>{list.namePlan}</h5>
                                                    <p className="card-text" style={{ marginTop: '15px' }}>
                                                        {`${currencySymbol}${list.price} per ${list.paymentFrecuency}`}
                                                    </p>
                                                    <div>
                                                    {list.description.split(',').map((desc, index) => (
                                                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem', flexWrap: 'wrap' }}>
                                                            <i className="bi bi-check" style={{ color: cardStyle.backgroundColor, fontSize: '1.7rem', marginRight: '0.5rem' }}></i>
                                                            <span style={{ flex: '1', wordWrap: 'break-word', whiteSpace: 'normal', wordBreak: 'break-word', maxWidth: '100%' }}>
                                                                {desc.trim()}
                                                            </span>
                                                        </div>
                                                    ))}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column align-items-center" style={{ marginBottom: '10px' }}>
                                                    <button onClick={() => handleContactClick(list.namePlan,cardStyle.backgroundColor)} className="btn btn-primary w-100" style={{ backgroundColor: cardStyle.backgroundColor, border: 'none', margin: '5px', height: '32px', maxWidth: '215px' }}>
                                                        {principal.contact}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    );
                                    })}
                                </div>
                            </div>
                        ))}
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#planCarousel" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">{principal.previous}</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#planCarousel" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">{principal.next}</span>
                        </button>
                    </div>
                </div>
    );

}
export default ViewPlan;