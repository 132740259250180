import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { UserProvider } from './context/User-context';
import ProtectedRoute from './components/Protected-route';
import App from './App';
import Unauthorized from './pages/Unauthorized';
import ViewLogin from './pages/ViewLogin';
import Login from './pages/Login';
import Home from './pages/Principal';
import HomeAdmin from './pages/Home-page-admin';
import TableCompany from './pages/Table-company';
import CreateBodyEmail from './pages/Create-body-email';
import BodyEmailList from './pages/Body-Email-list';
import TableCampaign from './pages/Table-campaign';
import TableClientMail from './pages/Table-client-mail';
import TableMailGroup from './pages/Table-mail-group';
import AdministratorReport from './pages/Administrator-report';
import HomeMaster from './pages/Home-page-master';
import TableAdministrator from './pages/Table-administrator'
import MasterReport from './pages/Master-report';
import PlanManagement from './pages/Plan-management';
import MasterConfiguration from './pages/Master-configuration';
import TableEmail from './pages/Table-email';
import store from './Store';
import { Provider } from 'react-redux';

const router = createBrowserRouter([
  {
    path: "App",
    element: (
      <App />
    ),
  },
  {
    path: "/",
    element: <Home />
  },
  {
    path: "ViewLogin",
    element: <ViewLogin />,
  },
  {
    path: "Login",
    element: <Login />
  },
  {
    path: "HomeMaster",
    element: (
      <ProtectedRoute allowedRoles={['Master']}>
        <HomeMaster />
      </ProtectedRoute>
    ),
  },
  {
    path: "HomeAdmin",
    element: (
      <ProtectedRoute allowedRoles={['Administrator']}>
        <HomeAdmin />
      </ProtectedRoute>
    ),
  },
  {
    path: "TableCompany",
    element: (
      <ProtectedRoute allowedRoles={['Administrator']}>
        <TableCompany />
      </ProtectedRoute>
    ),
  },
 
  {
    path: "TableCampaign",
    element: (
      <ProtectedRoute allowedRoles={['Administrator']}>
        <TableCampaign />
      </ProtectedRoute>
    ),
  },
  {
    path: "TableClientMail",
    element: (
      <ProtectedRoute allowedRoles={['Administrator']}>
        <TableClientMail />
      </ProtectedRoute>
    ),
  },
  {
    path: "TableCompany",
    element: (
      <ProtectedRoute allowedRoles={['Administrator']}>
        <TableCompany />
      </ProtectedRoute>
    ),
  },
  {
    path: "TableMailGroup",
    element: (
      <ProtectedRoute allowedRoles={['Administrator']}>
        <TableMailGroup />
      </ProtectedRoute>
    ),
  },
  {
    path: "TableAdministrator",
    element: (
      <ProtectedRoute allowedRoles={['Master']}>
        <TableAdministrator />
      </ProtectedRoute>
    ),
  },
  {
    path: "CreateEmail",
    element: (
      <ProtectedRoute allowedRoles={['Administrator']}>
        <CreateBodyEmail />
      </ProtectedRoute>
    ),
  },
  {
    path: "MailList",
    element: (
      <ProtectedRoute allowedRoles={['Administrator']}>
        <BodyEmailList />
      </ProtectedRoute>
    ),
  },
  {
    path: "TableEmail",
    element: (
      <ProtectedRoute allowedRoles={['Administrator']}>
        <TableEmail />
      </ProtectedRoute>
    ),
  },
  {
    path: "AdministratorReport",
    element: (
      <ProtectedRoute allowedRoles={['Administrator']}>
        <AdministratorReport />
      </ProtectedRoute>
    ),
  },
  {
    path: "MasterReport",
    element: (
      <ProtectedRoute allowedRoles={['Master']}>
        <MasterReport />
      </ProtectedRoute>
    ),
  },
  {
    path: "MasterConfiguration",
    element: (
      <ProtectedRoute allowedRoles={['Master']}>
        <MasterConfiguration />
      </ProtectedRoute>
    ),
  },
  {
    path: "PlanManagement",
    element: (
      <ProtectedRoute allowedRoles={['Master']}>
        <PlanManagement />
      </ProtectedRoute>
    ),
  },
  {
    path: "/unauthorized",
    element: <Unauthorized />,
  },

]);


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  </Provider>
);


