import React, { useState } from 'react';
import SidebarMaster from '../shared-components/Sidebar-master';
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";
import '../Styles/Master-report.css';

const MasterReport = () => {
    const [emailsSent, setEmailsSent] = useState(null);
    const [emailsDetails, setEmailsDetails] = useState([]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedInterval, setSelectedInterval] = useState("Intervals predefined");

    const fetchEmailsSent = async (startDate, endDate) => {
        try {
            const response = await fetch(`https://us-central1-vivamail-d987d.cloudfunctions.net/getEmailsSent?startDate=${startDate}&endDate=${endDate}`);
            const data = await response.json();

            const totalEmailsSent = data.result.stats.reduce((total, stat) => total + stat.delivered.total, 0);
            setEmailsSent(totalEmailsSent);
            console.log(data)

            // Extraer detalles diarios (fecha y cantidad de correos)
            const details = data.result.stats.map(stat => ({
                date: new Date(stat.time).toLocaleDateString("en-US"),
                count: stat.delivered.total,
            }));
            setEmailsDetails(details);
        } catch (error) {
            console.error('Error fetching emails sent:', error);
        }
    };

    const handleLastWeek = () => {
        setSelectedInterval("Last week");
        setSelectedDates([]);
        const today = new Date();
        const dayOfWeek = today.getDay();
        const lastMonday = new Date(today);
        lastMonday.setDate(today.getDate() - dayOfWeek - 6);
        lastMonday.setHours(0, 0, 0, 0);
        const lastSunday = new Date(today);
        lastSunday.setDate(today.getDate() - dayOfWeek);
        lastSunday.setHours(23, 59, 59, 999);
        const startDate = Math.floor(lastMonday.getTime() / 1000);
        const endDate = Math.floor(lastSunday.getTime() / 1000);
        fetchEmailsSent(startDate, endDate);
    };

    const handleLastMonth = () => {
        setSelectedInterval("Last month");
        setSelectedDates([]);
        const today = new Date();
        const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        firstDayLastMonth.setHours(0, 0, 0, 0);
        const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        lastDayLastMonth.setHours(23, 59, 59, 999);
        const startDate = Math.floor(firstDayLastMonth.getTime() / 1000);
        const endDate = Math.floor(lastDayLastMonth.getTime() / 1000);
        fetchEmailsSent(startDate, endDate);
    };

    const handleCustomInterval = () => {
        setSelectedInterval("Intervals predefined");
        if (selectedDates.length === 2) {
            const [start, end] = selectedDates;
            const today = new Date();
            today.setHours(23, 59, 59, 999);
            if (new Date(end) > today) {
                alert("The selected end date cannot be in the future.");
                return;
            }
            const startDate = Math.floor(new Date(start).setHours(0, 0, 0, 0) / 1000);
            const endDate = Math.floor(new Date(end).setHours(23, 59, 59, 999) / 1000);
            fetchEmailsSent(startDate, endDate);
        } else {
            alert("Please select a date range.");
        }
    };

    return (
        <div className='MasterReport'>
            <SidebarMaster />
            <div style={{ marginLeft: '16%', marginRight: '16%' }}>
                <p className="fs-3 fw-bold">Mails sent</p>
                <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                    <div className="dropdown" style={{ width: '550px', position: 'relative' }}>
                        <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{ width: '100%', backgroundColor: '#222527', display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '15px' }}>
                            {selectedInterval}
                        </button>
                        <ul className="dropdown-menu" style={{ width: '100%' }}>
                            <li><button className="dropdown-item" onClick={handleLastWeek}>Last week</button></li>
                            <li><button className="dropdown-item" onClick={handleLastMonth}>Last month</button></li>
                        </ul>
                    </div>

                    <div style={{ width: '350px', position: 'relative' }}>
                        <button className="btn btn-secondary" type="button" style={{ width: '100%', backgroundColor: '#222527' }}
                            onClick={() => document.getElementById('datePicker').classList.toggle('d-none')}>
                            Custom interval
                        </button>
                        <div id="datePicker" className="d-none mt-2" style={{ width: '100%', position: 'absolute', top: '100%', zIndex: 1 }}>
                            <DatePicker
                                value={selectedDates}
                                onChange={setSelectedDates}
                                range
                                numberOfMonths={2}
                                maxDate={new Date()}
                                style={{ width: '100%', minWidth: '350px', maxWidth: '550px', textAlign: 'center' }}
                            />
                            <button className="btn btn-primary mt-2" onClick={handleCustomInterval} style={{ width: '100%' }}>Submit</button>
                        </div>
                    </div>
                </div>
                <p className="fs-5 fw-semibold" style={{ marginTop: '7%' }}>Total emails sent: {emailsSent}</p>
                {emailsSent !== null && (
                    <div style={{ marginBottom: '3%', maxWidth: '920px', textAlign: 'center' }}>
                        <table className="table table-striped mt-3">
                            <tbody>
                                {emailsDetails.slice(1).reduce((rows, detail, index) => {
                                    if (index % 2 === 0) {
                                        rows.push([detail]);
                                    } else {
                                        rows[rows.length - 1].push(detail);
                                    }
                                    return rows;
                                }, []).map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {row.map((detail, colIndex) => (
                                            <td key={colIndex}>
                                                <strong> Fecha: </strong>  {detail.date}
                                                <strong style={{ marginLeft: '2%' }}> Emails enviados: </strong> {detail.count}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MasterReport;
