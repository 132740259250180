// src/pages/Unauthorized.js
import React from 'react';

const Unauthorized = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100">
      <h1>Acceso Denegado</h1>
      <p>No tienes permisos para ver esta página.</p>
      <a href="/" className="btn" style={{ backgroundColor: '#7839CD', color: 'white' }}>Ir al Inicio</a>
    </div>
  );
};

export default Unauthorized;
