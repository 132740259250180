export const homeMaster = {
    welcome: 'Welcome back, master'
}
export const loginAccess ={
    permission: "You don't have permissions to access",
    loadind: 'Loading...',
}

export const generalSideBar = {
    signOut: 'Sign Out'
}

export const principal = {
    login: 'Log In',
    plans: 'Plans',
    description: 'EMAIL SENDING AUTOMATION',
    plansdescription: 'Plans start from $**** per month',
    contact: 'Contact us',
    previous: 'Previous',
    next: 'Next'
}

export const createClient = {
    newClient: 'New Client',
    nameClient: 'Enter the name of the client',
    emailClient: 'Enter the email of the client',
    excelFile: 'Upload Excel File (The excel header must be "name" and "email")'
}

export const create = {
    remove: 'Remove',
    add: 'Add',
    cancel: 'Cancel',
    delete: 'Delete'
}
export const editClient = {
    editClient: 'Edit Client',
    newNameClient: 'New client name',
    newEmailClient: 'New client email'
}

export const sidebarAdmin = {
    company: 'Company',
    addCompany: 'Add new company',
    companies: 'View companies',
    emails: 'Emails',
    newMail: 'New mail',
    mailSent: 'Mails sent',
    campaign: 'Campaigns',
    addCampaign: 'Add campaigns',
    viewCampaign: 'View campaigns',
    clients: 'Clients',
    newClient: 'New client email',
    clientsMails: 'Clients mails',
    mailingGroups: 'Mailing groups',
    mailiGroups: 'New mailing group',
    viewGroups: 'View mailing groups',
    report: 'Reports',
    newreport: 'New report',
    
}

export const sideBarMaster = {
    admConfig: 'Administrators configuration',
    newAdmin: 'Add new administrator',
    viewAdmin: 'View administrators',
    reports: 'Reports',
    newreport: 'New report',
    plans: 'Plans',
    viewPlan: 'View Plans',
    addPlan: 'Add new plans',
    subscribers: 'View subscribers',
    config: 'Configuration',

}

export const tableComponent = {
    loading: 'Loading...',
    more: 'More',
    edit: 'Edit',
    delete: 'Delete',
    showing: 'Showing ',
    of: 'of ',
    pages: 'pages',
    prev: 'Prev',
    next: 'Next',
    name: 'Name',
    email: 'Email',
    state: 'State',
    previous: 'Previous'

}

export const tableCompany = {
    company: 'Company',

}

export const login = {
    emailAddress: 'Email Address:',
    password: 'Password',
    login: 'Log in',
    enterEmail: 'Enter Email',
    enterPassword: 'Enter Password'
}

export const planManagement = {
    namePLan: 'Enter the name of the plan',
    editPlan: 'Edit Plan',
    descriptionPlan: 'Enter descriptions separated by commas',
    numberCompanies: 'Enter the number of companies for the plan',
    price: 'Enter the price',
    payment: 'Select the payment frequency',
    year: 'Per year',
    month: 'Per month',
    week: 'Per week',
    titlePlan:'New Plan',
    nameRequired:'The plan name is required.',
    descriptionRequired:'The description is required.',
    descriptionLeght:'Description must be 100 characters or less.',
    companyRequired:'The number company is required.',
    numberCompany:'Must be a number.',
    priceRequired:'The price is required.',
    priceMust:'The price must be a number.',
    paymentFrequencyPlan:'The payment frequency is required.',
    toastSuccesPlan:'The plan has been successfully created.',
    planAlready:'The plan name already exists.',
    errorPlan:'An error occurred while saving the plan.',
    createPlan:'New Plan'
}

export const masterConfiguration = {
    frequencySendingEmails: 'Frequency of scheduling and sending of emails',
    enterInterval: 'Choose the interval in closed times (example 1 hours, 0 minutes)',
    applicationsForCompany: 'Applications for company creation',
    pendingCompany:'No pending companies',
    hoursInterval:'Hours',
    minutesInterval:'Minutes',
    saveInterval: 'Save Frequency',
    successInterval:'Frequency updated successfully!',
    errorInterval:'Failed to update frequency. Please try again.'
}

export const createPlan = {
    newPlan: 'New Plan',
    namePlan: 'Enter the name of the plan',
    planDesc: 'Enter descriptions separated by commas',
    numberOfCompanies: 'Enter the number of companies for the plan',
    planPrice: 'Enter the price',
    paymentFrequency: 'Select the payment frequency'
}

export const createCompany = {
    newCompany: 'New Company',
    nameCompany: 'Enter the company name',
    legalIdCompany: 'Enter the legal identification number',
    emailCompany: 'Email Address',

}

export const newAdministrator = {
    newAdmin: 'New Administrator',
    nameAdmin: 'Enter the administrator name',
    emailAdmin: 'Email Address',
    passwordAdmin: 'Password',
    selectPlan: 'Select a Plan',
    cancel: 'Cancel',
    add: 'Add'
}
export const messagesAdministrator = {
    adminRequired: 'Administrator name is required.',
    passwordRequired:  'Password is required.',
    passwordLong: 'Password must be at least 8 characters long.',
    emailRequired: 'Email is required.',
    emailValide: 'Please enter a valid email format including @ and .example.',
    planRequired: 'Plan is required.',
    adminCreate: 'Administrator has been sucsessfully created!',
    errorAdmin: 'An administrator with this email already exists.',
    errorSave:  'An error occurred while saving an administrator'
};

export const newEmail = {
    newMail: 'New Body Email',
    subject: 'Subject',
    save: 'Save', 
    back:'Back'

}

export const editCampaign = {
    editCampaign:'Edit Campaign', 
    nameCampaign: 'Campaign Name', 
    group: 'Select Mailing Group',
    calendar: 'Calendar',
    dates:'Select Dates'
}


export const sendEmail = {
    sendEmail: 'Send', 
    mailingGroups: 'Mailing Groups', 
    clientEmail: 'Client Emails', 
    dateSend: 'Date',   
    scheduleEmail: 'Schedule',
    clients: 'Select Client Emails',
    groups:'Select Mailing Group',
    bodyEmails:'Select Body Email',
    body: 'Body Email',
    sendNow: 'Send now',
    cancel: 'Cancel'
}

export const administratorReport = {
    administratorReport: 'Administrator Reports',
    selectEvent: 'Select an event',
    optionSelect: 'Select',
    optionAccepted: 'Accepted',
    optionDelivered: 'Delivered',
    startDate: 'Start Date',
    endDate: 'End Date',
    seeReport: 'See Report',
    events: 'Events',
    quantity: 'Amount'
}

export const modalCreateCampaign = {
    newCampaign: 'New Campaign',
    campaignName: 'Enter the campaign name',
    selectGroup: 'Select a group',
    selectDatesAndTimes: 'Select campaign dates and times',
    bodyEmailForDate: 'Body Email for date',
    selectBodyEmail: 'Select a Body Email',
    create: 'Create',
    cancel: 'Cancel'
}

export const customCalendar={
    month:'Month',
    week:'Week',
    today:'Today',
    day:'Day'
}

export const changePassword={
    titleChangePass:'Change Password',
    oldPass:'Enter your old password',
    newPass:'Enter the new password',
    confirmPass:'Confirm new password',
    cancelPass:'Cancel',
    changePass:'Change',
    errorOld:'Please check your old password.',
    errorRequired:'Old password is required.',
    errorLong:'The new password must be at least 8 characters long.',
    errorMatch:'New passwords do not match.',
    toastSuccess:'Password has been successfully changed.',
}

export const modalEditBodyEmail = {
    editEmail: 'Edit Body Email', 
    subject: 'Subject',
    body: 'Body',
    save: 'Save',
    cancel: 'Cancel'
}

export const modalEditEmail = {
    editEmail: 'Edit Email', 
    mailing: 'Mailing Groups',
    clients: 'Client Emails',
    sendDate:'Send Date',
    bodyEmail:'Body Email',
    selectBodyEmail: 'Select Body Email',
    sendNow: 'Send now',
    save: 'Save',
    cancel: 'Cancel'
}

export const mailbox = {
    mailbox: 'Mailbox',
    showing: 'Showing ',
    of: 'of ',
    pages: 'pages',
    send: 'Sent', 
    pending: 'Pending'
}
