// src/context/UserContext.js
import React, { createContext, useState, useContext } from 'react';

// Crear el contexto
const UserContext = createContext();

// Proveedor del contexto
export const UserProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(null); // Almacena el rol del usuario
  const [user, setUser] = useState(null); // Almacena la información del usuario

  return (
    <UserContext.Provider value={{ userRole, setUserRole, user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Hook personalizado para usar el contexto
export const useUserContext = () => {
  return useContext(UserContext);
};
