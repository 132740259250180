import { configureStore } from '@reduxjs/toolkit';
import modalReducer from './modalSlice';

const store = configureStore({
  reducer: {
    modal: modalReducer,  // Agrega el slice del modal al store
  },
});

export default store;
