import React, {useState} from "react";
import Swal from 'sweetalert2';
import { reauthenticateUser,changeUserPassword } from "../services/provider";
import {changePassword} from '../shared-components/WordsBank.js';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../Store/modalSlice';


const ModalChangePassword = ({ signOut })=>{
    const dispatch = useDispatch();
    const show = useSelector((state) => state.modal.isOpen);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);

   
    const handleChangePassword = async (e) => {
        e.preventDefault();
        setErrors({});
        let formErrors = {};

        if (!oldPassword) formErrors.oldPassword = changePassword.errorRequired;
        if (newPassword.length < 8) formErrors.newPassword = changePassword.errorLong;
        if (newPassword !== confirmNewPassword) formErrors.confirmNewPassword = changePassword.errorMatch;

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        try {
            await reauthenticateUser(oldPassword);
            await changeUserPassword(newPassword);
            setIsSuccess(true);
            setOldPassword("");
            setNewPassword("");
            setConfirmNewPassword("");

            Swal.fire({
                position: 'top-end',
                icon: 'success',
                text: changePassword.toastSuccess,
                showConfirmButton: false,
                timer: 1040,
                toast: true,
            }).then(() => {
                handleClose();
                setIsSuccess(false);
                signOut();
            });
        } catch (error) {
            setErrors({ oldPassword:changePassword.errorOld });
        }
    };

    const handleClose = () => {
        setErrors({});
        dispatch(closeModal());
    };

    if (!show) {
        return null;
    }


    return(
        <div className="modal d-block" style={{marginTop:'8vh'}}>
        <div className="modal-dialog" style={{ margin: 'auto', maxWidth: '100%', width: '500px' }}>
            <div className="modal-content" style={{ background: '#DFD8E2', padding: '28px', borderRadius: '10px', marginBottom: '50px' }}>
                <div class="modal-header">
                    <span className="modal-title text-black fs-3">{changePassword.titleChangePass}</span>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} style={{ filter: 'invert(1)', position:'absolute', top: '20px',right: '20px'}}></button>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleChangePassword}>
                        <div className="mb-3">
                            <label className="form-label text-black">{changePassword.oldPass}</label>
                            <input type="password" name='old-password' className="form-control" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                            {errors.oldPassword && <div className="text-danger">{errors.oldPassword}</div>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label text-black">{changePassword.newPass}</label>
                            <input type="password" name='new-password' className="form-control"  value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                            {errors.newPassword && <div className="text-danger">{errors.newPassword}</div>}
                        </div>
                        <div className="mb-3">
                            <label className="form-label text-black">{changePassword.confirmPass}</label>
                            <input type="password" name='confirm-new-password' className="form-control" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)}/>
                            {errors.confirmNewPassword && <div className="text-danger">{errors.confirmNewPassword}</div>}
                        </div>
                        <div class="modal-footer" style={{marginTop: '10px',marginBottom: '-35px' }}>
                                <button type="button" className="btn" style={{ color:'white',backgroundColor:'#DE3232',width: '80px', height: '40px' }} onClick={handleClose}>{changePassword.cancelPass}</button>
                                <button type="submit" className="btn" style={{ color:'white',backgroundColor:'#35D79C',width: '80px', height: '40px' }}>{changePassword.changePass}</button>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
    </div>
    );
}
export default ModalChangePassword;