// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUserContext } from '../context/User-context';

const ProtectedRoute = ({ allowedRoles, children }) => {
  const { user, userRole } = useUserContext();

  if (!user) {
    // Si el usuario no está autenticado, redirigir al login
    return <Navigate to="/Login" />;
  }

  if (!allowedRoles.includes(userRole)) {
    // Si el rol del usuario no está en los permitidos, redirigir a la página de no autorizado
    return <Navigate to="/unauthorized" />;
  }

  // Si todo está bien, renderizar el componente hijo
  return children;
};

export default ProtectedRoute;
