import React, {useEffect, useState} from 'react';
import {Calendar, dayjsLocalizer} from 'react-big-calendar';
import dayjs from 'dayjs';
import "react-big-calendar/lib/css/react-big-calendar.css"
import '../Styles/Home-page-admin.css'
import SidebarAdmin from '../shared-components/Sidebar-admin';
import CustomToolbar from '../components/Custom-calendar';
import {getDataCampaigns,getEmailScheduled} from '../services/provider.js';

const HomeAdmin = () => {
  const localizer = dayjsLocalizer(dayjs);
  const [events, setEvents] = useState([]); 

// Function to map campaign data and add it to events for the calendar
const eventsCampaigns = async () => {
  try {
    const campaigns = await getDataCampaigns();

    const eventsArray = campaigns.flatMap(campaign => {
      const { nameCampaign, datesCampaign, bodyEmails } = campaign;
      return datesCampaign.map((dateString, index) => {
        const date = dayjs(dateString, "YYYY/MM/DD HH:mm").toDate();
        return {
          title: `${nameCampaign} - ${bodyEmails[index] || "Mensaje"}`,
          start: date,
          end: date,
          type: 'campaign',
        };
      });
    });

    return eventsArray;
  } catch (error) {
    console.error("Error fetching campaigns:", error);
    return [];
  }
};
  //Function to map data from Scheduled emails and add it to events for the calendar
  const eventsEmails = async () => {
    try {
      const scheduledEmails = await getEmailScheduled();
  
      //Map each email to the format used by the calendar
      const emailEvents = scheduledEmails.map(email => {
        const startDate = dayjs(email.Date, "YYYY/MM/DD HH:mm").toDate();
        return {
          title: email.BodyEmail || 'Email Programado',
          start: startDate,
          end: startDate, 
          type: 'email'  
        };
      });

      console.log(emailEvents)
      return emailEvents;      
    } catch (error) {
      console.error("Error fetching email:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const campaigns = await eventsCampaigns();   
      const emails = await eventsEmails();        
      setEvents([...campaigns, ...emails]);       
    };

    fetchEvents();
  }, []);

  //Styles to differentiate between campaign and scheduled emails
  const eventStyleGetter = (event) => {
    let backgroundColor;
    let color;
    let border;
    if (event.type === 'campaign') {
      backgroundColor = '#35D79C';
      color = '#000000';
      border='1px solid #3cb98b';
    } else if (event.type === 'email') {
      backgroundColor = '#D02F7C'; 
      color = '#ffffff';
      border='1px solid #bf216d';
    }
  
    return {
      style: {
        backgroundColor,
        borderRadius: '5px',
        opacity: 0.8,
        color,
        border,
        display: 'block'
      },
    };
  };
  

  return (
    <div className='home-admin'>
      <SidebarAdmin/>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10'>
            <div className='container-calendar' style={{height: '83vh', margin: '0 auto'}}>
              <Calendar 
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              components={{
                toolbar: CustomToolbar,
              }}
              eventPropGetter={eventStyleGetter}
              popup
              dayLayoutAlgorithm="no-overlap" 
              />
            </div>
          </div>
        </div>
        </div>
    </div> 
  );
};

export default HomeAdmin;