import React, {useState} from 'react'
import Principal from './pages/Principal';

function App() {
    return(
      <div className='App'>
        <Principal/>
      </div>
    );
}

export default App;
