import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { checkIfMailingGroupExists, createEmailGroup, getClientsEmails } from '../services/provider';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../Store/modalSlice';


const ModalCreateGroup = () => {
    const defaultEntry = {
        nameEmailGroup: '',
    };

    const defaultErrors = { nameEmailGroup: '', global: '' };
    const [mailingGroup, setMailingGroup] = useState(defaultEntry);
    const [errors, setErrors] = useState(defaultErrors);
    const [emailInput, setEmailInput] = useState('');
    const [availableEmails, setAvailableEmails] = useState([]); 
    const [loadingEmails, setLoadingEmails] = useState(true);
    const [errorEmails, setErrorEmails] = useState(null); 
    const [filteredEmails, setFilteredEmails] = useState([]);
    const [selectedEmails, setSelectedEmails] = useState([]);

    const dispatch = useDispatch();
    const show = useSelector((state) => state.modal.isOpen);
    // Obtain e-mails 
    useEffect(() => {
        const fetchEmails = async () => {
            try {
                const emails = await getClientsEmails();
                setAvailableEmails(emails); 
            } catch (error) {
                console.error('Error fetching emails:', error);
                setErrorEmails('Error fetching emails.');
            } finally {
                setLoadingEmails(false);
            }
        };

        if (show) {
            fetchEmails(); 
        }
    }, [show]);

    // Function to reset the form
    const resetForm = () => {
        setMailingGroup(defaultEntry);
        setErrors(defaultErrors);
        setEmailInput('');
        setSelectedEmails([]);
    };

    const captureInputs = (e) => {
        const { name, value } = e.target;
        setMailingGroup({ ...mailingGroup, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    // Filter mails when the user writes
    const handleEmailInputChange = (e) => {
        const input = e.target.value;
        setEmailInput(input);

        // Filter the available options according to the input
        if (input.length > 0) {
            const filtered = availableEmails.filter(email => email.toLowerCase().includes(input.toLowerCase()));
            setFilteredEmails(filtered);
        } else {
            setFilteredEmails([]);
        }
    };

    // Add the selected mail to the list of selected mails
    const selectEmail = (email) => {
        // Check if the mail is already on the list
        if (!selectedEmails.includes(email)) {
            setSelectedEmails([...selectedEmails, email]);
        }
        setEmailInput(''); // Clear the input field
        setFilteredEmails([]); // Clear filtered list
    };

    const removeSelectedEmail = (email) => {
        const updatedEmails = selectedEmails.filter(e => e !== email);
        setSelectedEmails(updatedEmails);
    };

    const validateInputs = () => {
        const newErrors = { ...defaultErrors };
        let isValid = true;

        if (!mailingGroup.nameEmailGroup) {
            newErrors.nameEmailGroup = 'Group name is required.';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const saveGroup = async (e) => {
        e.preventDefault();
        if (!validateInputs()) return;

        // Save the mail group
        try {
            const groupExists = await checkIfMailingGroupExists(mailingGroup.nameEmailGroup);
            if (groupExists) {
                setErrors({ ...errors, nameEmailGroup: 'A group with this name already exists, try another one' });
                return;
            }

            if(selectedEmails.length === 0){
                setErrors({ ...errors, global: 'You can not create an empty group' });
                return;
            }

            await createEmailGroup({
                ...mailingGroup,
                emails: selectedEmails,
                numberMembers: selectedEmails.length
            });
            resetForm();
            dispatch(closeModal());

            Swal.fire({
                position: 'top-end',
                icon: 'success',
                text: 'The group has been successfully created',
                showConfirmButton: false,
                timer: 5000,
                toast: true,
            });
        } catch (error) {
            console.error('Error saving group:', error);
            setErrors({ ...errors, global: 'An error occurred while saving the group.' });
        }
    };

    const handleClose = () => {
        resetForm();
        dispatch(closeModal());
    };

    if (!show) {
        return null;
    }

    
    return (
        <div className="modal d-block" style={{ background: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '50px' }}>
            <div className="modal-dialog" style={{ margin: 'auto', maxWidth: '100%', width: '640px' }}>
                <div className="modal-content" style={{ background: 'black', padding: '28px', borderRadius: '10px', marginBottom: '50px' }}>
                    <div className="modal-header" style={{ marginBottom: '10px' }}>
                        <span className="modal-title text-white fs-3">New Mailing Group</span>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose} style={{ filter: 'invert(1)', marginBottom: '30px' }}></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={saveGroup}>
                            <div className="mb-3">
                                <label className="form-label text-white">Enter the group name</label>
                                <input onChange={captureInputs} value={mailingGroup.nameEmailGroup} type="text" name='nameEmailGroup' className="form-control" />
                                {errors.nameEmailGroup && <div className='text-danger'>{errors.nameEmailGroup}</div>}
                            </div>

                            {/* Field to write emails and dropdown of options */}
                            <div className="mb-3">
                                <label className="form-label text-white">Add emails to the group</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={emailInput}
                                    onChange={handleEmailInputChange}
                                    placeholder="Type to search emails..."
                                />
                                {loadingEmails ? (
                                    <div className="text-white">Loading emails...</div>
                                ) : errorEmails ? (
                                    <div className="text-danger">{errorEmails}</div>
                                ) : filteredEmails.length > 0 ? (
                                    <ul className="dropdown-menu show" style={{ width: '100%' }}>
                                        {filteredEmails.map(email => (
                                            <li key={email} onClick={() => selectEmail(email)} className="dropdown-item">
                                                {email}
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                            </div>

                            {/* Show selected emails */}
                            <div className="mb-3">
                                <label className="form-label text-white">Selected emails</label>
                                <div className='bg-white p-2' style={{ borderRadius: '5px', maxHeight: '200px', overflowY: 'auto', width: '500px', margin: '0 auto' }}>
                                    {selectedEmails.map((email, index) => (
                                        <div key={email} className="d-flex justify-content-between align-items-center mb-2" style={{ borderBottom: index !== selectedEmails.length - 1 ? '1px solid #ddd' : 'none', paddingBottom: '8px' }}>
                                            <span className='ms-2'>{email}</span>
                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => removeSelectedEmail(email)}>Remove</button> 
                                        </div>
                                    ))}
                                    <div className="d-flex justify-content-end">
                                        <span>Total: {selectedEmails.length}</span>
                                    </div>
                                </div>
                            </div>

                            {errors.global && <div className='text-danger mb-3'>{errors.global}</div>}

                            <div className="modal-footer" style={{ marginTop: '40px', marginBottom: '-28px' }}>
                                <button type="button" className="btn btn-danger" style={{ width: '80px', height: '40px', marginTop: '25px' }} onClick={handleClose}>Cancel</button>
                                <button type="submit" className="btn btn-success" style={{ width: '80px', height: '40px', marginTop: '25px' }}>Add</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCreateGroup;
