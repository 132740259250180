import React, { useState, useEffect } from 'react';
import SidebarMaster from '../shared-components/Sidebar-master';
import '../Styles/Master-configuration.css';
import { masterConfiguration } from '../shared-components/WordsBank';
import { fetchCompany , handleAcceptCompany, handleDeleteCompany, fetchFrequencySending, updateFrequencySending} from '../services/provider';
import Swal from 'sweetalert2';

const MasterConfiguration = () => {
  const [companies, setCompanies] = useState([]);  //State to store companies
  const [hours, setHours] = useState();           //Status for hours
  const [minutes, setMinutes] = useState();       //Status for minutes

  //Function to get the companies with "accepted" set to false
  const getPendingCompanies = async () => {
    const allCompanies = await fetchCompany();
    // Filter the companies that have accepted as false
    const pendingCompanies = allCompanies.filter(company => company.accepted === false);
    setCompanies(pendingCompanies);
  };

  //Function to get the hours and minutes values ​​when the component loads
  const loadFrequencySending = async () => {
    try {
      const { hours, minutes } = await fetchFrequencySending();
      setHours(hours);
      setMinutes(minutes);
    } catch (error) {
      console.error("Error loading frequency sending:", error);
    }
  };

  //Function to update the interval
  const handleSaveInterval = async () => {
    try {
      await updateFrequencySending(hours, minutes);
      
      Swal.fire({
        position: 'top-end',       
        icon: 'success',           
        text: masterConfiguration.successInterval,  
        showConfirmButton: false,  
        timer: 3000,              
        toast: true                
      });
    } catch (error) {
      console.error("Error updating frequency:", error);
  
      Swal.fire({
        position: 'top-end',       
        icon: 'error',             
        text: masterConfiguration.errorInterval,  
        showConfirmButton: false, 
        timer: 3000,              
        toast: true                
      });
    }
  };
  

  // useEffect to obtain the companies and intervals when the component is assembled
  useEffect(() => {
    getPendingCompanies();
    loadFrequencySending();
  }, []);
  

  return (
    <div className='master-configuration'>
      <div><SidebarMaster /></div>
      <div className='container-master-configuration' style={{ backgroundColor: 'rgba(223, 216, 226, 0.8)', width: '50%', padding: '40px', marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'column' }}>
      {/*Section for time interval*/}
      <div className="row justify-content-center mb-3" style={{ width: '100%', maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'column' }}>
          <div className="col-12 col-md-8 col-lg-6 bg-white p-4 rounded shadow-sm" style={{ width: '100%' }}>
            <h5 className="text-left mb-4" style={{ fontWeight: '350' }}>{masterConfiguration.frequencySendingEmails}</h5>
            <div className="form-group mt-3" style={{ margin: '3%' }}>
              <label htmlFor="timeInterval" className="form-label fs-6" style={{ fontWeight: 'bold' }}>{masterConfiguration.enterInterval}</label>
              <div className="d-flex align-items-center">
                {/*Hours selector*/}
                <div className="form-group me-2 w-100" style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="hours" className="form-label">{masterConfiguration.hoursInterval}</label>
                  <input type="number" id="hours" className="form-control" value={hours} onChange={(e) => setHours(e.target.value)} min="0" max="23"/>
                </div>
                {/*Minutes selector*/}
                <div className="form-group me-2 w-100" style={{ display: 'flex', flexDirection: 'column' }}>
                  <label htmlFor="minutes" className="form-label">{masterConfiguration.minutesInterval}</label>
                  <input type="number" id="minutes" className="form-control" value={minutes} onChange={(e) => setMinutes(e.target.value)} min="0" max="59"/>
                </div>
                {/*Update button*/}
                <div className="form-group w-100" style={{ display: 'flex', flexDirection: 'column' }}>
                  <button className="btn w-100" onClick={handleSaveInterval} style={{ marginTop:'19%', backgroundColor:'#35D79C', color:'#fff'}}>{masterConfiguration.saveInterval}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Section for notifications (companies)*/}
        <div className="row justify-content-center" style={{ width: '100%', maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'column' }}>
          <div className="col-md-8 bg-white p-4 rounded shadow-sm" style={{ width: '100%' }}>
            <h5 className="text-left" style={{ marginBottom: '4%', fontWeight: '350' }}>{masterConfiguration.applicationsForCompany}</h5>
            {companies.length > 0 ? (
              companies.map((company) => (
                <div className="input-group mb-3" key={company.id}>
                  {/* Div showing the company name*/}
                  <div className="form-control mt-3" style={{ backgroundColor: '#f8f9fa' }}>
                    {company.companyName} {/*Make sure this field exists in your document*/}
                  </div>
                  <div className="input-group-append">
                    <button
                      className="btn mt-3"
                      style={{ backgroundColor: '#DE3232', color: '#fff', opacity: '0.7', borderRadius: '5px', padding: '8px 15px' }}
                      type="button"
                      onClick={() => {{handleDeleteCompany(company.id, companies, setCompanies)}}}  //Delete company by clicking
                    >
                      <i className="bi bi-x-circle"></i>
                    </button>
                    <button
                      className="btn mt-3"
                      style={{ backgroundColor: '#35D79C', color: '#fff', opacity: '0.7', borderRadius: '5px', padding: '8px 15px' }}
                      type="button"
                      onClick={() =>{ handleAcceptCompany(company.id, companies, setCompanies)}}  //Change accepted a true
                    >
                      <i className="bi bi-check-circle"></i>
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>{masterConfiguration.pendingCompany}</p>  //Message if there are no pending companies
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterConfiguration;
