import React, { useState, useEffect } from 'react';
import { getClientsData, getDataGroups, getBodyEmails, getEmailsFromGroup, updateEmailData } from '../services/provider';
import DatePicker from 'react-multi-date-picker';
import Select from 'react-select';
import Swal from 'sweetalert2';
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { modalEditEmail } from '../shared-components/WordsBank';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../Store/modalSlice';

const EditEmailModal = ({ emailData, onSave}) => {
    const [groups, setGroups] = useState([]);
    const [clientEmails, setClientEmails] = useState([]);
    const [bodyEmail, setBodyEmail] = useState([]);
    const [selectedEmails, setSelectedEmails] = useState(emailData.selectedEmails || []);
    const [selectedOption, setSelectedOption] = useState(emailData.isGroup ? 'groups' : 'clients');
    const [selectedBodyEmail, setSelectedBodyEmail] = useState(emailData.selectedBodyEmail || '');
    const [sendDate, setSendDate] = useState(emailData.sendDate ? new Date(emailData.sendDate) : new Date());
    const [sendNow, setSendNow] = useState(emailData.isSend || false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();
    const show = useSelector((state) => state.modal.isOpen);  // Obtén el estado de visibilidad del modal desde Redux
    
    useEffect(() => {
        const fetchData = async () => {
            const clients = await getClientsData();
            const groups = await getDataGroups();
            const bodyEmails = await getBodyEmails();
            setClientEmails(clients.map(client => ({ value: client.email, label: client.email })));
            setGroups(groups.map(group => ({ value: group.nameEmailGroup, label: group.nameEmailGroup })));
            setBodyEmail(bodyEmails);
        };
        fetchData();
    }, []);

    

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
        setSelectedEmails([]);
    };

    const handleSelectChange = (selectedOptions) => {
        setSelectedEmails(selectedOptions || []);
    };

    const handleGroupChange = async (selectedGroup) => {
        setSelectedEmails([]);
        if (selectedGroup) {
            const emails = await getEmailsFromGroup(selectedGroup.value);
            const emailOptions = emails.flat().map(email => ({ value: email, label: email }));
            setSelectedEmails(emailOptions);
        }
    };

    const handleUpdate = async () => {
        setError(null);
        
        if (!Array.isArray(selectedEmails) || selectedEmails.length === 0) {
            handleError('You must select at least one addressee.');
            return;
        }

        if (!selectedBodyEmail) {
            handleError('You must select a mail content.');
            return;
        }

        if (!sendNow && !sendDate) {
            handleError('You must select a date for the scheduled shipment.');
            return;
        }

        if (!emailData || !emailData.id) {
            handleError("No valid email ID found.");
            return;
        }

        const EmailData = {
            id: emailData.id,
            selectedEmails: selectedEmails.map(option => option.value),
            selectedBodyEmail: selectedBodyEmail,
            sendDate: sendNow ? new Date() : sendDate,
            isSend: sendNow,
        };
        
        console.log("Datos de correo a actualizar:", EmailData); // Verificar los datos, incluyendo el ID
        const result = await updateEmailData(EmailData);
        if (result.error) {
            handleError(result.error);
            return;
        }
        setIsSuccess(true);
        dispatch(closeModal());
    };

    const resetForm = () => {
        setSelectedEmails(emailData.selectedEmails || []);
        setSelectedOption(emailData.isGroup ? 'groups' : 'clients');
        setSelectedBodyEmail(emailData.selectedBodyEmail || '');
        setSendDate(emailData.sendDate ? new Date(emailData.sendDate) : new Date());
        setSendNow(emailData.isSend || false);
    };

    const handleBodyEmailChange = (e) => {
        setSelectedBodyEmail(e.target.value);
    };

    const handleClose = () => {
        resetForm();
        dispatch(closeModal());
    };

    const handleError = (message) => {
        setError(message);
        setTimeout(() => setError(null), 3000);
    };

    useEffect(() => {
        if (isSuccess) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                text: 'Email has been updated!',
                showConfirmButton: false,
                timer: 5000,
                toast: true,
            });
            setIsSuccess(false);
        }
    }, [isSuccess]);

    if (!show) return null;

    return (
        <div
            className={`modal ${show ? 'd-block' : 'd-none'}`}
            style={{
                background: 'rgba(0,0,0,0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '50px',
            }}
        >
            <div className="modal-dialog" style={{ margin: 'auto', maxWidth: '100%', width: '640px' }}>
                <div className="modal-content" style={{ background: '#DFD8E2', padding: '28px', borderRadius: '10px', marginBottom: '50px' }}>
                    <div className="modal-header" style={{ marginBottom: '10px' }}>
                        <span className="modal-title text-black fs-3">{modalEditEmail.editEmail}</span>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={handleClose}
                            style={{ filter: 'invert(1)', marginBottom: '30px' }}
                        ></button>
                    </div>
                    <div className="modal-body">
                        {error && (
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        )}
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="mb-3">
                                <label className="form-label text-black">{modalEditEmail.mailing}</label>
                                <div>
                                    <input
                                        type="radio"
                                        value="groups"
                                        checked={selectedOption === 'groups'}
                                        onChange={handleOptionChange}
                                        className="me-2"
                                    />
                                    <label className="text-black me-4">{modalEditEmail.mailing}</label>
                                    <input
                                        type="radio"
                                        value="clients"
                                        checked={selectedOption === 'clients'}
                                        onChange={handleOptionChange}
                                        className="me-2"
                                    />
                                    <label className="text-black">{modalEditEmail.clients}</label>
                                </div>
                            </div>

                            {selectedOption && (
                                <div className="mb-3">
                                    <label className="form-label text-black">
                                        {selectedOption === 'clients' ? "Clients" : "Groups"}
                                    </label>
                                    {selectedOption === 'clients' ? (
                                        <Select
                                            options={clientEmails}
                                            isMulti
                                            value={selectedEmails}
                                            onChange={handleSelectChange}
                                            placeholder="Select Clients"
                                            required
                                        />
                                    ) : (
                                        <Select
                                            options={groups}
                                            onChange={handleGroupChange}
                                            placeholder="Select Groups"
                                            required
                                        />
                                    )}
                                </div>
                            )}

                            <div className="mb-3" style={{ textAlign: 'inherit' }}>
                                <label className="form-label text-black" style={{ marginRight: '20px' }}>{modalEditEmail.sendDate}</label>
                                <DatePicker
                                    value={sendDate}
                                    onChange={(date) => setSendDate(new Date(date))}
                                    disabled={sendNow}
                                    plugins={[<TimePicker position="bottom" />]} // Selección de horas
                                    className="form-control mt-2"
                                    style={{ width: '250px', height: '10%', background: 'white' }}
                                />
                            </div>

                            <div className="mb-3">
                                <label className="form-label text-black">{modalEditEmail.bodyEmail}</label>
                                <select
                                    value={selectedBodyEmail}
                                    onChange={handleBodyEmailChange}
                                    className="form-select mt-2"
                                    required
                                >
                                    <option value="">{modalEditEmail.selectBodyEmail}</option>
                                    {bodyEmail.map((bodyEmail) => (
                                        <option key={bodyEmail.id} value={bodyEmail.body}>
                                            {bodyEmail.subject}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="mb-3">
                                <label className="form-check-label text-black">
                                    <input
                                        type="checkbox"
                                        checked={sendNow}
                                        onChange={() => setSendNow(!sendNow)}
                                        className="form-check-input me-2"
                                    />
                                    Send now
                                </label>
                            </div>
                        </form>
                    </div>

                    <div className="modal-footer" style={{ marginBottom: '-25px' }}>
                        <button type="button" className="btn btn-danger" style={{ width: '100px', height: '40px' }} onClick={handleClose}>
                            {modalEditEmail.cancel}
                        </button>
                        <button type="button" className="btn btn-success" style={{ width: '100px', height: '40px'}} onClick={() => {handleUpdate(); onSave()}}>
                            {modalEditEmail.save}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditEmailModal;
