import React, { useState } from 'react';
import SidebarAdmin from '../shared-components/Sidebar-admin';
import TableComponent from '../shared-components/Table-component';
import ModalViewMore from '../shared-components/Modal-view-more.jsx';
import ModalDelete from '../shared-components/Modal-delete.jsx';
import ModalCreateMailGroup from '../components/Modal-create-mail-group.jsx'
import { updateMailingGroup, checkIfMailingGroupExists, getEmailsFromGroup, getClientsEmails, getCurrentUserId } from '../services/provider.js';
import '../Styles/Background-Table.css'
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { openModal } from '../Store/modalSlice';


const TableMailGroup = () => {

    const dispatch = useDispatch();

    const userId = getCurrentUserId();

    const handleViewClick = (item) => {
        // Calls the modal display function from TableComponent
        showModal('view', item);
    };

    const handleDeleteClick = (item) => {
        // Calls the modal removal function from the TableComponent
        showModal('delete', item);
    };


    const handleCreateClick = () => {
        dispatch(openModal());
    };

    const handleEditClick = async (item) => {
        // Obtain group emails 
        const emailsFromGroup = await getEmailsFromGroup(item.nameEmailGroup);
        // Access internal array (emailsFromGroup[0]) and format emails
        let selectedEmails = emailsFromGroup[0] || []; // If there are no mails, use an empty array
        let emailInput = '';
        let filteredEmails = [];
        let availableEmails = await getClientsEmails();

        const { value: formValues } = await Swal.fire({
            html: `
                <div style="text-align: left; margin-left: 38px; margin-top: 30px;">
                    <h4 style="margin-bottom: 45px; font-weight: bold;">Edit Mailing Group</h4>
                    <div style="margin-bottom: 25px;">
                        <label for="swal-input1" style="display: block; margin-bottom: 5px;">New mailing group name</label>
                        <input id="swal-input1" class="swal2-input" style="width: 92%; margin: 0; background: #FFFFFF;" value="${item.nameEmailGroup}">
                    </div>
    
                    <div style="margin-bottom: 25px;">
                        <label for="swal-input2" style="display: block; margin-bottom: 5px;">Add emails to the group</label>
                        <input id="swal-input2" class="swal2-input" placeholder="Type to search emails..." style="width: 92%; margin: 0; background: #FFFFFF;">
                        <ul id="filtered-email-list" class="dropdown-menu" style="display: none; max-height: 150px; overflow-y: auto; width: 92%; position: absolute; z-index: 1000;"></ul>
                    </div>
    
                    <div style="margin-bottom: 25px; margin-left: 29px;">
                        <label style="display: block; margin-bottom: 5px;">Group emails</label>
                        <div id="email-list" style="max-height: 170px; max-width: 450px; overflow-y: auto; border: 1px solid #ddd; padding: 10px; border-radius: 5px; background: #FFFFFF;">
                            ${selectedEmails.map(email => `
                                <div style="display: flex; justify-content: space-between; margin: 10px;">
                                    <span>${email}</span>
                                    <button class="btn btn-danger btn-sm remove-email" data-email="${email}">Remove</button>
                                </div>
                            `).join('')}
                        </div>
                        <div id="total-count" style="margin-top: 10px; margin-right: 90px; text-align: right;">
                            Total: ${selectedEmails.length}
                        </div>
                    </div>
                </div>
            `,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: 'Save',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#35D79C',
            cancelButtonColor: '#DE3232',
            showCloseButton: true,
            background: '#DFD8E2',
            width: '650px',
            allowOutsideClick: false,
            didRender: () => {
                const emailInputElem = document.getElementById('swal-input2');
                const filteredEmailListElem = document.getElementById('filtered-email-list');
                const emailListElem = document.getElementById('email-list');
                const totalCountElem = document.getElementById('total-count'); // Referencia al total

                // Handling of input to filter emails
                emailInputElem.addEventListener('input', () => {
                    emailInput = emailInputElem.value;
                    if (emailInput.length > 0) {
                        filteredEmails = availableEmails.filter(email =>
                            email.toLowerCase().includes(emailInput.toLowerCase()) && !selectedEmails.includes(email)
                        );
                        // Show filtered options
                        filteredEmailListElem.innerHTML = filteredEmails.map(email => `
                            <li class="dropdown-item">${email}</li>
                        `).join('');
                        filteredEmailListElem.style.display = 'block';

                        // Manage click on filtered options
                        document.querySelectorAll('#filtered-email-list li').forEach((li) => {
                            li.addEventListener('click', () => {
                                const selectedEmail = li.textContent;
                                selectedEmails.push(selectedEmail);
                                emailListElem.innerHTML += `
                                    <div style="display: flex; justify-content: space-between; margin: 10px;">
                                        <span>${selectedEmail}</span>
                                        <button class="btn btn-danger btn-sm remove-email" data-email="${selectedEmail}">Remove</button>
                                    </div>
                                `;
                                filteredEmailListElem.style.display = 'none';
                                emailInputElem.value = ''; // Clean input
                                totalCountElem.innerHTML = `Total: ${selectedEmails.length}`; // Update total
                            });
                        });
                    } else {
                        filteredEmailListElem.style.display = 'none'; // Hide the list if there is no input
                    }
                });

                // Handle deletion of selected mails
                emailListElem.addEventListener('click', (e) => {
                    if (e.target && e.target.matches('button.remove-email')) {
                        const emailToRemove = e.target.getAttribute('data-email');
                        selectedEmails = selectedEmails.filter(email => email !== emailToRemove);
                        e.target.parentElement.remove(); // Visually remove mail
                    }
                });
            },
            preConfirm: async () => {
                const nameEmailGroup = Swal.getPopup().querySelector('#swal-input1').value;

                if (!nameEmailGroup) {
                    Swal.showValidationMessage('Please enter the name of the group');
                    return false;
                }

                if (selectedEmails.length === 0) {
                    Swal.showValidationMessage('The group can not be empty, add an email');
                    return false;
                }

                try {
                    const name = await checkIfMailingGroupExists(nameEmailGroup, item.id);
                    if (name) {
                        Swal.showValidationMessage('This name is already in use, try another one');
                        return false;
                    }
                } catch (error) {
                    Swal.showValidationMessage('Error checking data. Please try again later.');
                    return false;
                }

                return { nameEmailGroup, selectedEmails };
            }
        });

        if (formValues) {
            const updatedData = {
                nameEmailGroup: formValues.nameEmailGroup,
                emails: selectedEmails.flat(),
                numberMembers: selectedEmails.length,
                lastUpdate: new Date()
            }
            try {
                await updateMailingGroup(item.id, updatedData);
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    text: 'The group has been updated',
                    showConfirmButton: false,
                    timer: 5000,
                    toast: true,
                });
            } catch (error) {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    text: 'Update Failed',
                    showConfirmButton: false,
                    timer: 5000,
                    toast: true,
                });
            }
        }
    };



    // To format the date to show it
    const formatTimestamp = (timestamp) => {
        if (!timestamp) return '';
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    };

    const formatEmails = (emails) => {
        if (!Array.isArray(emails) || emails.length === 0) return ''; // If it is not an array or is empty, returns an empty string
        return emails.join('\n'); // Join emails with line breaks
    };

    const showModal = (modalType, item) => {
        switch (modalType) {
            case 'view':
                ModalViewMore({
                    title: 'Mailing Group Details',
                    fields: [
                        { label: 'Group name ', key: 'nameEmailGroup' },
                        { label: 'Number of members', key: 'numberMembers' },
                        { label: 'Creation date', key: 'creationDate', format: formatTimestamp },
                        { label: 'Last update date', key: 'lastUpdate', format: formatTimestamp },
                        { label: 'Members', key: 'emails', format: formatEmails }
                    ],
                    data: item
                });
                break;
            case 'delete':
                ModalDelete({
                    item,
                    title: 'Mail Group',
                    collectionName: 'EmailGroup',
                    warningMessage: 'You will lose it forever',
                    onSuccessMessage: 'The group has been deleted!',
                });
                break;
            default:
                break;
        }
    };

    return (
        <div className='Background-Table'>
            <SidebarAdmin />
            <TableComponent
                tittle={'Mailing Groups'}
                collectionName="EmailGroup"
                columnName={['Name Group']}
                columnsToShow={['nameEmailGroup']}
                handleViewClick={handleViewClick}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                handleCreateClick={handleCreateClick}
                currentUserId={userId}
                adminFieldName={'adminID'}
            />
            <ModalCreateMailGroup/>
        </div>
    );

}
export default TableMailGroup;