import React, { useEffect, useState } from 'react';
import { fetchTotalDocuments, fetchData, subscribeToCollection } from '../services/provider.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { tableComponent } from './WordsBank.js';

function TableComponent({ tittle, collectionName, columnName, columnsToShow, handleViewClick, handleEditClick, handleDeleteClick, handleCreateClick, currentUserId, adminFieldName }) {
    const [data, setData] = useState([]); // Stores the fetched data
    const [loading, setLoading] = useState(false);
    const [lastVisible, setLastVisible] = useState(null); // Tracks the last visible document for pagination
    const [pageSize, setPageSize] = useState(5); // Number of items per page
    const [searchTerm, setSearchTerm] = useState(''); // Stores the search term
    const [currentPage, setCurrentPage] = useState(1); // Tracks the current page
    const [totalPages, setTotalPages] = useState(0); // Stores total pages based on document count
    const [firstVisiblePages, setFirstVisiblePages] = useState([]); // Tracks first visible documents for pagination

    // Function to load data from the collection with optional pagination and search
    const loadData = async (options = {}) => {
        const { isNextPage = false, isPrevPage = false } = options;
        setLoading(true);
        try {
            const result = await fetchData({ collectionName, searchTerm, columnsToShow, lastVisible, isNextPage, isPrevPage, firstVisiblePages, pageSize, currentUserId, adminFieldName });
            setData(result.documents);
            setLastVisible(result.lastVisible);

            // Update pagination state based on page navigation
            if (isNextPage) {
                setFirstVisiblePages([...firstVisiblePages, result.firstVisible]);
                setCurrentPage(currentPage + 1);
            } else if (isPrevPage) {
                setFirstVisiblePages(firstVisiblePages.slice(0, -1));
                setCurrentPage(currentPage - 1);
            }

            const totalDocuments = await fetchTotalDocuments(collectionName, currentUserId, adminFieldName);
            setTotalPages(Math.ceil(totalDocuments / pageSize));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const unsubscribe = subscribeToCollection( collectionName, loadData, currentUserId, adminFieldName);
        return () => unsubscribe(); // Cleanup subscription on component unmount
    }, [collectionName, pageSize, currentPage, firstVisiblePages, currentUserId, adminFieldName]);

    useEffect(() => {
        if (searchTerm.trim() !== '') {
            const timeoutId = setTimeout(() => {
                setData([]);           // Limpiar los datos actuales
                setLastVisible(null);   // Resetear paginación
                setFirstVisiblePages([]); 
                loadData();             // Cargar los datos con el nuevo término de búsqueda
            }, 400); // Espera 400ms antes de realizar la búsqueda
    
            return () => clearTimeout(timeoutId);  // Limpiar el timeout si el usuario sigue escribiendo
        } else {
            setData([]);           // Si el término de búsqueda está vacío, cargar todos los datos
            setLastVisible(null);
            setFirstVisiblePages([]);
            loadData();
        }
    }, [searchTerm]);
    

    const loadNext = () => {
        if (currentPage < totalPages) {
            loadData({ isNextPage: true }); // Load next page
        }
    };

    const loadPrev = () => {
        if (currentPage > 1) {
            loadData({ isPrevPage: true }); // Load previous page
        }
    };

    const handlePageSizeChange = (event) => {
        setPageSize(Number(event.target.value)); // Update page size based on selection
        setData([]); // Reset data
        setLastVisible(null); // Reset last visible
        setFirstVisiblePages([]); // Reset first visible pages
        setCurrentPage(1); // Reset to first page
        loadData(); // Load data with new page size
    };

     // Update search term as user types
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        if (searchTerm.trim() !== '') {
            setData([]);
            setLastVisible(null);
            setFirstVisiblePages([]);
            loadData();
        } else {
            setData([]);
            setLastVisible(null);
            setFirstVisiblePages([]);
            loadData();
        }
    };

    return (
        <div className="container mt-4">
            <h1 className='text-white'>{tittle}</h1>

            <form onSubmit={handleSearchSubmit} className="form-inline mb-3 d-flex align-items-center justify-content-end" >
                <button onClick={() => { handleCreateClick() }} type="button" className="btn btn-success me-5" style={{ fontSize: '18px' }}>
                    <i className="bi bi-plus-square" style={{ color: 'white' }}></i>
                </button>

                <div className="input-group" style={{ maxWidth: '300px' }}>
                    <input type="text" placeholder="Search" value={searchTerm} onChange={handleSearchChange} className="form-control" />
                    <button className="input-group-text" onChange={handleSearchChange} >
                        <i className="bi bi-search" ></i>
                    </button>
                </div>
            </form>

            {loading ? (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">{tableComponent.loading}</span>
                    </div>
                </div>
            ) : (
                <div className="table-responsive rounded" style={{ maxHeight: '350px', overflowY: 'auto' }}>
                    <table className="table table-hover">
                        <thead className="thead-dark text-center">
                            <tr>
                                {columnName.map((column) => (
                                    <th className='text-white' style={{ background: '#222527' }} key={column}>{column}</th>
                                ))}
                                <th className='text-white' style={{ background: '#222527', width: '8%' }}>{tableComponent.more}</th>
                                <th className='text-white' style={{ background: '#222527', width: '8%' }}>{tableComponent.edit}</th>
                                <th className='text-white' style={{ background: '#222527', width: '8%' }}>{tableComponent.delete}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr key={item.id}>
                                    {columnsToShow.map((column) => (
                                        <td key={column} style={{ textAlign: "center" }}>{item[column]}</td>
                                    ))}
                                    <td style={{ textAlign: "center" }}>
                                        <button onClick={() => handleViewClick(item)} className="btn btn-primary btn-sm">
                                            <i className="bi bi-three-dots" style={{ fontSize: '18px', color: 'white' }}></i>
                                        </button>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <button onClick={() => handleEditClick(item)} className="btn btn-warning btn-sm">
                                            <i className="bi bi-pencil" style={{ fontSize: '18px', color: 'white' }}></i>
                                        </button>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        <button onClick={() => handleDeleteClick(item)} className="btn btn-danger btn-sm">
                                            <i className="bi bi-trash3" style={{ fontSize: '18px', color: 'white' }}></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <div className="d-flex justify-content-between align-items-center mt-3">
                <span className='font-weight-bold text-white'>{tableComponent.showing} {currentPage} {tableComponent.of} {totalPages} {tableComponent.pages}</span>
                <div className='d-flex justify-content-between align-items-center'>
                    <button onClick={loadPrev} disabled={currentPage === 1 || loading} className="btn btn-light d-inline-block m-1" style={{ background: 'rgba(255, 255, 255, 0.5)' }}>
                        {tableComponent.prev}
                    </button>
                    <select id="pageSize" value={pageSize} onChange={handlePageSizeChange} className="form-control d-inline-block m-1" style={{ width: '30%', height: '38.5px', textAlign: "center" }}>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                    </select>
                    <button onClick={loadNext} disabled={currentPage === totalPages || loading} className="btn btn-light d-inline-block m-1" style={{ background: 'rgba(255, 255, 255, 0.5)' }}>
                        {tableComponent.next}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TableComponent;