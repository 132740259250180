import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../Styles/Modal-edit_body.css';
import { updateBodyEmail } from '../services/provider.js'
import { modalEditBodyEmail } from '../shared-components/WordsBank.js';


function EmailEditor({ isOpen, onClose, item }) {
  const [editSubject, setEditSubject] = useState('');
  const [editBody, setEditBody] = useState('');

  

  useEffect(() => {
    if (item) {
      setEditSubject(item.subject || ''); // Inicializa el editor con el subject
      setEditBody(item.body || ''); // Inicializa el editor con el body
    }
  }, [item]);

  // Guardar cambios en Firebase
  const handleSave = async () => {
    await updateBodyEmail(item, editSubject, editBody)
    onClose();  // Cerrar el modal después de guardar
  };

   // Agregar o remover la clase 'modal-open' al body dependiendo del estado del modal
   useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isOpen]);

  return (
    <div>
      {/* Modal */}
      <div className={`modal fade ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none', zIndex: '1050',  transform: 'translate(-50%, -50%)' }} tabIndex="-1">
        <div className="modal-dialog modal-lg" style={{ maxWidth: '700px'}}>
          <div className="modal-content" style={{}}>
            <div className="modal-header">
              <h5 className="modal-title">{modalEditBodyEmail.editEmail}</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
            </div>
            <div className="modal-body">
              <div>
                <label style={{padding:'5px'}}>{modalEditBodyEmail.subject}</label>
                <input 
                  type="text" 
                  value={editSubject} 
                  onChange={(e) => setEditSubject(e.target.value)} 
                  className="form-control"
                />
              </div>
              <div>
                <label style={{padding:'5px'}}>{modalEditBodyEmail.body}</label>
                <ReactQuill 
                  value={editBody} 
                  onChange={setEditBody} 
                  style={{ height: '200px' }}
                />
              </div>
            </div>
            <div className="modal-footer" style={{justifyContent:'center'}}>
              <button type="button" className="btn btn-primary" style={{backgroundColor: 'rgb(53, 215, 156)', color:'white', border:'none'}} onClick={handleSave}>{modalEditBodyEmail.save}</button>
              <button type="button" className="btn btn-secondary" style={{backgroundColor: 'rgb(222, 50, 50)', color:'white', border:'none'}} onClick={onClose}>{modalEditBodyEmail.cancel}</button>
            </div>
          </div>
        </div>
      </div>

      {/* Backdrop */}
      {isOpen && <div className="modal-backdrop fade show" style={{ zIndex: '1040' }}></div>}
    </div>
  );
}

export default EmailEditor;