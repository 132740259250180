import React, { useState } from 'react';
import SidebarAdmin from '../shared-components/Sidebar-admin.jsx';
import ModalDelete from '../shared-components/Modal-delete.jsx';
import TableComponent from '../shared-components/Table-component.jsx';
import ModalViewMore from '../shared-components/Modal-view-more.jsx';
import { getCurrentUserId } from '../services/provider.js';
import '../Styles/Background-Table.css';
import EmailEditor from '../components/Modal-edit-body-email.jsx';
import { useNavigate } from 'react-router-dom';

const BodyEmailList = () =>{
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null); // Estado para el ítem seleccionado
    const userId = getCurrentUserId();
    const navigate = useNavigate();

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return '';
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    };
    
    const handleViewClick = (item) => {
        // Calls the modal display function from TableComponent
        showModal('view', item);
    };

    const handleDeleteClick = (item) => {
        // Calls the modal removal function from the TableComponent
        showModal('delete', item);
    };

    const handleModalClose = () => {
        setIsModalOpen(false); // Closes the modal
    };

    const handleCreateClick = () => {
        navigate("/CreateEmail");
    };

    const handleEditClick = async (item) => {
        setSelectedItem(item); // Guarda el item que se está editando
        setIsModalOpen(true);  // Abre el modal
    };

    const showModal = (modalType, item) => {
        switch (modalType) {
            case 'view':
                ModalViewMore({
                    title: 'Body Emails Details',
                    fields: [
                        { label: 'Subject', key: 'subject' },
                        { label: 'Body', key: 'body' },
                        { label: 'Last update date', key: 'creationDate', format: formatTimestamp },
                        { label: 'Last update date', key: 'lastUpdate', format: formatTimestamp },
                    ],
                    data: item
                });
                break;
            case 'delete':
                ModalDelete({
                    item,
                    title: 'Body Email',
                    collectionName: 'BodyEmail',
                    warningMessage: 'You will lose it forever',
                    onSuccessMessage: 'Email Body has been deleted!',
                });
                break;
            default:
                break;
        }
    };

    return(
        <div className='Background-Table'>
            <SidebarAdmin/>
            <TableComponent
                tittle={'Body Email'}
                collectionName="BodyEmail"
                columnName={['Subject']}
                columnsToShow={['subject']}
                handleViewClick={handleViewClick}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick}
                handleCreateClick={handleCreateClick}
                currentUserId={userId}
                adminFieldName={'userID'}
            />
            {/*Importar el modal de crear*/}
            <EmailEditor 
                isOpen={isModalOpen} 
                onClose={handleModalClose} 
                item={selectedItem}  // Pasar el ítem seleccionado al modal
            />
        </div>
    );

}
export default BodyEmailList; 