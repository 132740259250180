import React, { useState, useEffect } from 'react';
import '../Styles/Background-Table.css';
import SidebarAdmin from '../shared-components/Sidebar-admin';
import SendEmailModal from '../components/Modal-send-email';
import { loadEmails, deleteEmail } from '../services/provider';
import ModalViewMore from '../shared-components/Modal-view-more.jsx';
import Swal from 'sweetalert2';
import EditEmailModal from '../components/Modal-edit-email.jsx';
import { mailbox } from '../shared-components/WordsBank.js';
import { useDispatch, useSelector } from 'react-redux';
import { openModal, closeModal } from '../Store/modalSlice';

const TableEmail = () => {
    const [emails, setEmails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [showModal, setShowModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true); 
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [selectedEmailData, setSelectedEmailData] = useState(null);
    const dispatch = useDispatch();
    const show = useSelector((state) => state.modal.isOpen); 


    const fetchData = async () => {
        setLoading(true);
        try {
            await loadEmails(setEmails); // Llama a la función loadEmails y actualiza el estado
        } catch (error) {
            console.error("Error loading emails:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filteredEmails = emails.filter(email =>
        email.BodyEmail?.toLowerCase().includes(searchText.toLowerCase())
    );

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredEmails.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredEmails.length / itemsPerPage);

    // Functions to change pages
    const goToNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    const goToPreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

    // Function to change the number of items per page
    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to the first page when changing items per page
    };

    // To format the date to show it
    const formatTimestamp = (timestamp) => {
        if (!timestamp) return '';
        const date = new Date(timestamp.seconds * 1000);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    };

    const formatDate= (value, key) => {
        if (key === 'Date' && typeof value === 'string') {
            return value; // Devuelve el valor directamente si ya es una cadena
        }
        return value; // No aplica ningún cambio para otros valores o claves
    };
    

    const handleModalView = (item) => {
        ModalViewMore({
            title: 'Email Details',
            fields: [
                { label: 'Email Body', key: 'BodyEmail' },
                { label: 'Date', key: 'Date', format: (value) => formatDate(value, 'Date') },
                { label: 'Emails', key: 'Emails' },
                { label: 'Company Email', key: 'companyEmail' },
                { label: 'Send', key: 'isSend' },
                { label: 'Creation date', key: 'creationDate', format: formatTimestamp },
                { label: 'Last update date', key: 'lastUpdate', format: formatTimestamp },
            ],
            data: item,
        });
    };

    const openEditModal = (email) => {
        console.log("Email seleccionado para editar:", email); // Verifica el objeto email y su ID
        setSelectedEmailData(email);
        dispatch(openModal());
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        dispatch(closeModal());
        setSelectedEmailData(null); // Limpia los datos al cerrar
        setEditModalOpen(false);
    };

    const handleSaveChanges = () => {
        fetchData(); // Actualiza la lista de correos después de guardar cambios
        closeEditModal(); // Cierra el modal
    };

    /* Call to the delete modals*/
    const handleDelete = async (item) => {
        const result = await Swal.fire({
            title: `<div style="text-align: left;">Delete Email<hr style="border: 1px solid #5A5555;"></div>`,
            html: `
                <div>
                    <p>Are you sure you want to delete this email?</p>
                    <p style="margin-bottom: 2px;">You will lose everything</p>
                </div>
            `,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#CB2A2A',
            cancelButtonColor: '#423F3F',
            showCloseButton: true,
            allowOutsideClick: false,
            width: '460px',
        });

        if (result.isConfirmed) {
            const success = await deleteEmail(item.id);

            if (success) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'The email has been deleted',
                    showConfirmButton: false,
                    timer: 5000,
                    toast: true,
                });
                await fetchData();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Delete Failed',
                    text: 'There was an error deleting the email. Please try again later.',
                });
            }
        }
    };

    return (
        <div className="Background-Table">
            <SidebarAdmin />
            <div className="container mt-4">
                <h2 className="mb-4" style={{ color: 'white' }}>{mailbox.mailbox}</h2>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex ms-auto">
                        <button
                            type="button"
                            className="btn btn-success me-2"
                            style={{ fontSize: '18px', backgroundColor: '#7839CD', border: 'none' }}
                            onClick={() => dispatch(openModal()) }
                        >
                            <i className="bi bi-send" style={{ color: 'white' }}></i>
                        </button>

                        <div className="input-group" style={{ maxWidth: '500px' }}>
                            <input
                                type="text"
                                placeholder="Search"
                                className="form-control"
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <button className="input-group-text">
                                <i className="bi bi-search"></i>
                            </button>
                        </div>

                        <SendEmailModal show={show} onClose={() => { setShowModal(false); fetchData(); }} />
                    </div>
                </div>

                {/* Display loading message or email table */}
                {loading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-light" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                    </div>
                ) : (
                    <table className="table table-hover mt-3" style={{ borderRadius: '7px', overflow: 'hidden' }}>
                        <tbody>
                            {currentItems.map((email) => (
                                <tr key={email.id}>
                                    <td style={{ paddingLeft: '25px', verticalAlign: 'middle' }}>{email.BodyEmail}</td>
                                    <td className="text-end" style={{ paddingLeft: '25px', verticalAlign: 'middle' }}>
                                        {email.isSend ? mailbox.send : mailbox.pending}
                                    </td>
                                    <td className="text-end">
                                        {email.isSend ? (
                                            <button
                                                className="btn btn-sm btn-primary"
                                                style={{ margin: '2px', marginRight: '5px' }}
                                                onClick={() => handleModalView(email)}
                                            >
                                                <i className="bi bi-three-dots" style={{ fontSize: '18px', color: 'white' }}></i>
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-sm btn-warning"
                                                style={{ margin: '2px', marginRight: '5px' }}
                                                onClick={() => openEditModal(email)}
                                            >
                                                <i className="bi bi-pencil" style={{ fontSize: '18px', color: 'white' }}></i>
                                            </button>
                                        )}
                                        <button
                                            className="btn btn-sm btn-danger"
                                            style={{ margin: '2px', marginRight: '5px' }}
                                            onClick={() => handleDelete(email)}
                                        >
                                            <i className="bi bi-trash3" style={{ fontSize: '18px', color: 'white' }}></i>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {/* Pagination controls */}
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <span style={{ color: 'white' }}>{mailbox.showing} {currentPage} {mailbox.of} {totalPages} {mailbox.pages}</span>
                    <div className="d-flex justify-content-between align-items-center">
                        <button
                            className="btn btn-light d-inline-block m-1"
                            onClick={goToPreviousPage}
                            disabled={currentPage === 1}
                        >
                            <i className="bi bi-chevron-left"></i>
                        </button>

                        <select
                            className="form-control d-inline-block m-1"
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                            style={{ width: '30%', height: '38.5px', textAlign: "center" }}
                        >
                            <option value={15}>15</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                        </select>

                        <button
                            className="btn btn-light d-inline-block m-1"
                            onClick={goToNextPage}
                            disabled={currentPage === totalPages}
                        >
                            <i className="bi bi-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>

            {/* Modal para editar correos */}
            {isEditModalOpen && (
                <EditEmailModal
                    emailData={selectedEmailData}
                    onSave={handleSaveChanges}
                />
            )}
        </div>
    );
};

export default TableEmail;
