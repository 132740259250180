import React, { useState } from 'react';
import SidebarAdmin from '../shared-components/Sidebar-admin';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../services/credentials';
import '../Styles/Administrator-report.css';
import { administratorReport } from '../shared-components/WordsBank';

const AdministratorReport = () => {
  const [acceptedCount, setAcceptedCount] = useState(0);
  const [deliveredCount, setDeliveredCount] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showReport, setShowReport] = useState(false);

  // Función para formatear la fecha en "YYYY/MM/DD"
  const formatDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const day = String(newDate.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const fetchReportData = async (eventType) => {
    try {
      if (!startDate || !endDate) {
        alert('Por favor, selecciona un rango de fechas');
        return;
      }

      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      console.log(formattedStartDate)

      let eventQuery = query(
        collection(db, "ReportsAdmin"),
        where("event", "==", eventType),
        where("timestamp", ">=", formattedStartDate),
        where("timestamp", "<=", formattedEndDate)
      );

      const snapshot = await getDocs(eventQuery);
      
      if (eventType === "accepted") {
        setAcceptedCount(snapshot.size);
      } else if (eventType === "delivered") {
        setDeliveredCount(snapshot.size);
      }
    } catch (error) {
      console.error("Error al obtener los datos de Firestore:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowReport(true);
    fetchReportData(selectedEvent);
  };

  return (
    <div className='AdministratorReport'>
      <SidebarAdmin />
      <div className="container mt-4">
        <h2>{administratorReport.administratorReport}</h2>

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="eventSelect" className="form-label">{administratorReport.selectEvent}</label>
            <select
              id="eventSelect"
              className="form-select"
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e.target.value)}
            >
              <option value="">{administratorReport.optionSelect}</option>
              <option value="accepted">{administratorReport.optionAccepted}</option>
              <option value="delivered">{administratorReport.optionDelivered}</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="startDate" className="form-label">{administratorReport.startDate}</label>
            <input
              type="date"
              id="startDate"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="endDate" className="form-label">{administratorReport.endDate}</label>
            <input
              type="date"
              id="endDate"
              className="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

          <button type="submit" className="btn btn-primary">{administratorReport.seeReport}</button>
        </form>

        {showReport && selectedEvent && (
          <div className="row mt-4">
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">{administratorReport.events} {selectedEvent.charAt(0).toUpperCase() + selectedEvent.slice(1)}</h5>
                  <p className="card-text">
                    {administratorReport.quantity}: {selectedEvent === "accepted" ? acceptedCount : deliveredCount}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdministratorReport;
